
import { DashboardMenu } from "../../data/menu"
import BreadCrump from "../tools/breadcrump"
import { getCookies } from "cookies-next";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import changeUrl from "../../hoc/changeur";
import { config } from "../../data/config";
import ImageUploader from "../tools/uploaderImage"
import { useTranslation } from 'react-i18next';

export default function UserList() {
    const { t } = useTranslation();

    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },
        {
            name: 'User',
            url: '/dashboard/User'
        },
        {
            name: 'User List',
            url: null
        },
    ]
    const reloadgame = () => {
        let x = key + 1;
        setKey(x);
    }
    const [key, setKey] = useState(1);


    return (
        <>
            <div className="text-left  bg-white  p-3 m-4 directions   h-[100vh] mb-5 ">
                <BreadCrump data={breadcrump}></BreadCrump>
                <div className="mb-3 ">

                    <div className=" text-[30px] font-bold text-blue-900">{t('User List')}</div>
                    <hr />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <NewBlogCategory reloaditem={reloadgame}></NewBlogCategory>

                    </div>
                    <div className="col-span-3">
                        <ListofBlog key={key}></ListofBlog>
                    </div>
                </div>
            </div>

        </>
    )
}
function ListofBlog() {
    const { t } = useTranslation();

    let cookie = getCookies();
    const [list, setList] = useState<any>(null);
    const [name, setName] = useState<any>('');
    const [group, setGroup] = useState<any>('');
    const [listgroup, setListArticle] = useState<any>(null);

    const [error, setError] = useState<any>(null);
    const [key, setKey] = useState<any>(1);
    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm border-blue-500 ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ";

    const loaddata = async (page = 1) => {
        try {
            let res = await axios({
                method: 'get',
                url: `${config.url}/v1/dashboard/users?page=${page}&name=${name}&group=${group}`,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });

            let mydata = res.data.message;
            setList(mydata);

        } catch (error) {

        }
    }
    const getalldata = async () => {
        setError(null);

        try {
            let res = await axios({
                method: 'get',
                url: config.url + '/v1/dashboard/users',
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            })

            setListArticle(res.data.message);
        } catch (error) {
        }
    }

    const deleted = async (id: any) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Delete this Post!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let res = await axios({
                        method: 'delete',
                        url: config.url + '/v1/dashboard/users/' + id,
                        headers: {
                            Authorization: 'Bearer ' + cookie['token'],
                        }
                    })

                    loaddata(1);
                } catch (error) {
                }
            }
        });

    }
    useEffect(() => {
        loaddata(1);
        getalldata();
    }, []);
    return (
        <div>
            <div className="grid grid-cols-2 pb-4">
                <div>
                    <p className="font-bold " onClick={(e) => { loaddata(1); }}>{t('List')}</p>

                </div>
                <div className="  gap-1 w-full">
                    {/* <button type="button" onClick={(e) => { getalldata() }} className="float-end py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none ">
                        Reload
                    </button> */}
                </div>
            </div>
            <hr />

            <div className="flex flex-col ">
                <div className="container m-auto grid grid-cols-5 gap-3">
                    <div className="col-span-2">
                        <label className="text-white"> Slug </label>
                        <input
                            onChange={(e) => { setName(e.target.value) }}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Search" />

                    </div>

                    <div className="col-span-1 pt-6">
                        <button
                            onClick={(e) => { loaddata(1); }}
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-4 border border-gray-400 rounded shadow">
                            {t('Filter')}
                        </button>
                    </div>

                </div>
                <div className="-m-1.5 overflow-x-auto">
                    <div className="p-1.5 min-w-full inline-block align-middle">
                        <div className="overflow-hidden">

                            <table className="min-w-full divide-y divide-gray-200 ">
                                <thead>
                                    <tr>
                                        <th scope="col" className="px-6 py-3  text-xs font-medium text-gray-500 uppercase ">  {t('Name')}    </th>
                                        <th scope="col" className="px-6 py-3  text-xs font-medium text-gray-500 uppercase ">{t('Last Name')} </th>
                                        <th scope="col" className="px-6 py-3  text-xs font-medium text-gray-500 uppercase ">{t('E-mail')} </th>
                                        <th scope="col" className="px-6 py-3  text-xs font-medium text-gray-500 uppercase ">{t('Rule')} </th>
                                        <th scope="col" className="px-6 py-3  text-xs font-medium text-gray-500 uppercase  ">{t('Action')} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list != null && list.docs.map((item: any) => {
                                        return (
                                            <tr className="odd:bg-white even:bg-gray-100 hover:bg-gray-100 ">
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">{item.name}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 "> {item.lastname}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">{item.email}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">{item.usertype == 1 ? t('Admin') : t('User')}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium flex gap-2">
                                                    <a
                                                        href={`/dashboard/User/list/${item.id}`}
                                                        type="button"
                                                        className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none "
                                                    >{t('Edit')} </a>

                                                    <button
                                                        onClick={(e) => { deleted(item.id) }}
                                                        type="button"
                                                        className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none "
                                                    >{t('Delete')} </button>
                                                </td>

                                            </tr>
                                        )
                                    })}



                                </tbody>
                            </table>
                            <div className="mt-4">
                                <nav className="flex items-center -space-x-px" aria-label="Pagination">
                                    {list != null && list.prevPage != null &&
                                        <button
                                            onClick={(e) => { loaddata(list.page - 1); }}
                                            type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm first:rounded-s-lg last:rounded-e-lg border border-gray-200 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" aria-label="Previous">
                                            <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="m15 18-6-6 6-6"></path>
                                            </svg>
                                            <span className="hidden sm:block">{t('Previous')} </span>
                                        </button>
                                    }
                                    {list != null &&
                                        Array.from({ length: list.totalPages }, (_, index) => index + 1).map((item) => {
                                            return (
                                                list.page == item ?
                                                    <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center bg-gray-200 text-gray-800 border border-gray-200 py-2 px-3 text-sm first:rounded-s-lg last:rounded-e-lg focus:outline-none focus:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-600 dark:border-neutral-700 dark:text-white dark:focus:bg-neutral-500" aria-current="page">{item}</button>

                                                    :
                                                    <button
                                                        onClick={(e) => { loaddata(item); }}
                                                        type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-lg last:rounded-e-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">{item}</button>
                                            )

                                        })
                                    }

                                    {list != null && list.nextPage != null &&
                                        <button
                                            onClick={(e) => { loaddata(list.page + 1); }}
                                            type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm first:rounded-s-lg last:rounded-e-lg border border-gray-200 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none " aria-label="Next">
                                            <span className="hidden sm:block">{t('Next')} </span>
                                            <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="m9 18 6-6-6-6"></path>
                                            </svg>
                                        </button>
                                    }
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function NewBlogCategory({ reloaditem }: any) {
    const { t } = useTranslation();

    let cookie = getCookies();
    const [data, setData] = useState<any>({
        name: '',
        url: '',
        keywords: '',
        description: '',
        parent: null

    });
    const [list, setListArticle] = useState<any>(null);

    const [error, setError] = useState<any>(null);
    const [key, setKey] = useState<any>(1);
    const setName = (name: any) => {

        var x = changeUrl(name);
        setData({ ...data, url: x, name: name });
    }

    const save = async () => {
        setError(null);

        try {
            let res = await axios({
                method: 'post',
                url: config.url + '/v1/dashboard/users',
                data: data,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });

            let mydata = res.data;
            reloaditem();
            let x = key + 1;
            setKey(x);
            return mydata;
        } catch (error: any) {

            error.response.data.code != null && Swal.fire(` "${data.url}" slug is available.`);
            setError(error.response.data.message.errors);
        }
    }
    const getalldata = async () => {
        // setError(null);

        // try {
        //     let res = await axios({
        //         method: 'get',
        //         url: config.url + '/v1/dashboard/users',
        //         headers: {
        //             Authorization: 'Bearer ' + cookie['token'],
        //         }
        //     })

        //     setListArticle(res.data.message);
        // } catch (error) {
        // }
    }
    useEffect(() => {
        getalldata();
    }, []);
    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none";
    return (
        <div className="p-3 bg-gray-500 rounded-[10px] p-5">
            <h4 className="text-white text-[20px] font-bold ">{t('New User')}</h4>
            <hr />

            <form key={key}>
                <div className="max-w-sm space-y-3">
                    <label className="text-white"> {t('Name')}  </label>
                    <input
                        type="text"
                        className={`${inputstyle}     ${error != null && error['name'] != undefined && `bg-red-100`} `}
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                    />
                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                </div>
                <div className="max-w-sm space-y-3 mt-4">
                    <label className="text-white">{t('Email')}   </label>
                    <input
                        value={data.url}
                        type="text"
                        className={`${inputstyle}       ${error != null && error['url'] != undefined && `bg-red-100`}  `}
                        placeholder="Email"
                        onChange={(e) => setData({ ...data, email: e.target.value })}
                    />
                    {error != null && error['url'] != undefined && <small>{error['url']['message']}</small>}
                </div>
                <div className="max-w-sm space-y-3 mt-4">
                    <label className="text-white"> {t('Type')}    </label>
                    <select

                        onChange={(e) => setData({ ...data, parent: e.target.value })}

                        className="py-3 px-4 pe-9 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:focus:ring-neutral-600"
                    >
                        <option value={1}> {t('Admin')}  </option>
                        <option value={2}>{t('User')}   </option>

                    </select>
                </div>
                <div>

                </div>

                <div className="max-w-sm space-y-3 mt-4">
                    <label className="text-white">{t('Keywords')}    </label>
                    <textarea
                        className={inputstyle}
                        onChange={(e) => setData({ ...data, keywords: e.target.value })}
                        placeholder="..." />
                </div>
                <div className="max-w-sm space-y-3 mt-4">
                    <label className="text-white">{t('Description')}   </label>
                    <textarea
                        className={inputstyle}
                        onChange={(e) => setData({ ...data, description: e.target.value })}
                        placeholder="..." />
                </div>
                <div className="max-w-sm space-y-3 mt-4">
                    <button type="button"
                        onClick={(e) => { save() }}
                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:focus:bg-white/20 dark:focus:text-white"
                    >
                        {t('Save')}
                    </button>

                </div>
            </form>
        </div>
    )
}