import { useParams } from 'react-router-dom';
import { config } from "../../data/config";
import { useEffect } from 'react';
import { getCookies } from "cookies-next";
import { useCookies } from 'react-cookie';
import axios from "axios";
import { useState } from "react";
import BreadCrump from "../tools/breadcrump"

import * as React from 'react';
import TissEditor from '../Editor/TissEditor';
import ImageUploader from '../tools/uploaderImage';
import ImageUploaders from '../tools/uploaderimages';
import { useTranslation } from 'react-i18next';
import { Selection } from 'ckeditor5';
import Swal from 'sweetalert2';

export default function MenuDetail() {
    let cookie = getCookies();
    const { t } = useTranslation();

    let { Id } = useParams();
    const [cookies, setCookie] = useCookies<any>(['token', 'rule']);
    const [key, setKey] = useState<any>(1);
    const [error, setError] = useState<any>(null);
    const [child, setChild] = useState<any>(null);
    const [show, setShow] = useState<boolean>(false);
    const [edit, setEdit] = useState<any>(null);

    const [data, setData] = React.useState<any>(null);
    const Addmenu = () => {
        var x = key + 1;
        setEdit(null);
        setChild(null);
        setShow(true);
        setKey(x);
    }
    const save = () => {

    }
    const showedit = (id: any) => {
        var x = key + 1;

        setEdit(id);
        setChild(null);
        setShow(true);
        setKey(x);
    }
    const load = async () => {
        try {
            var path = `${config.url}/v1/dashboard/menus/${Id}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setData(res.data.message)
        } catch (error) {

        }
    }
    const showallmenus = async () => {
        var x = key + 1;
        setChild(null);
        setShow(false);
        setKey(x);


    }
    const addChild = async (id: any) => {
        var x = key + 1;
        setChild(id);
        setShow(true);
        setKey(x);


    }
    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none";
    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },
        {
            name: 'Feature',
            url: '/dashboard/Blog'
        },
        {
            name: 'Menus List',
            url: '/dashboard/Feature/Menus'
        },
        {
            name: 'Edit Menu',
            url: null
        }
    ]
    useEffect(() => {
        load();
    }, []);
    return (
        <div className='directions'>

            <BreadCrump data={breadcrump}></BreadCrump>



            {
                data != null && <div className='grid grid-cols-1 sm:grid-cols-5 directions'>
                    <div >

                        <div className="p-3 bg-gray-500 rounded-[10px] p-5">
                            <h4 className="text-white text-[20px] font-bold ">{t('Edit Detail Image')}</h4>
                            <hr />
                            <form key={key}>
                                <div className="max-w-sm space-y-3">
                                    <label className="text-white">{t('Name')}  </label>
                                    <input
                                        defaultValue={data.name}
                                        type="text"
                                        className={`${inputstyle}     ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="text-white">{t('Slug')}  </label>
                                    <input
                                        type="text"
                                        defaultValue={data.url}
                                        className={`${inputstyle}       ${error != null && error['url'] != undefined && `bg-red-100`}  `}
                                        placeholder="Slug"
                                        onChange={(e) => setData({ ...data, url: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['url']['message']}</small>}
                                </div>


                                <div className="max-w-sm space-y-3 mt-4">
                                    <button type="button"
                                        onClick={(e) => { save() }}
                                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:focus:bg-white/20 dark:focus:text-white"
                                    > {t('Update')}

                                    </button>

                                </div>
                            </form>



                        </div>
                    </div>
                    <div className='col-span-1 sm:col-span-4 p-5'>
                        <div>
                            <button
                                onClick={(e) => {
                                    Addmenu()
                                }}
                                type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-500 text-white hover:bg-gray-600 focus:outline-none focus:bg-gray-600 disabled:opacity-50 disabled:pointer-events-none">
                                {t('Add Menu')}
                            </button>
                        </div>
                        <hr />
                        {show == true && <AddMenu edit={edit} key={key} child={child} parent={data._id} showallmenus={showallmenus}></AddMenu>}
                        {show == false && <ReloadMenu showedit={showedit} parent={data._id} addChild={addChild} ></ReloadMenu>}

                    </div>
                </div>
            }

        </div>
    )
}

export function ReloadMenu({ parent, addChild, showedit }: any) {
    let cookie = getCookies();
    const { t } = useTranslation();
    const [data, setData] = React.useState<any>(null);
    const arrowup = async (myid: any) => {
        //orderup
        try {
            var path = `${config.url}/v1/dashboard/menudetail/orderup/${myid}`;

            await axios({
                method: 'put',
                url: path,

                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            load();
        } catch (error) {

        }
    }
    const arrowdown = async (myid: any) => {
        //orderup
        try {
            var path = `${config.url}/v1/dashboard/menudetail/orderdown/${myid}`;

            await axios({
                method: 'put',
                url: path,

                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            load();
        } catch (error) {

        }
    }
    //
    const deleted = async (myid: any) => {
        //orderup
        try {
            var path = `${config.url}/v1/dashboard/menudetail/${myid}`;

            await axios({
                method: 'delete',
                url: path,

                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            load();
        } catch (error) {
            Swal.fire(t('have sub menu'))
        }
    }
    const edited = async (id: any) => {
        showedit(id);

    }
    const load = async () => {
        try {
            var path = `${config.url}/v1/dashboard/menudetail/${parent}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setData(res.data.message)
        } catch (error) {

        }
    }
    useEffect(() => {
        load();
    }, []);
    return (
        <div className='pt-4'>
            <button
                onClick={(e) => {
                    load();
                }}
                type="button"
                className="py-3 m-auto  px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-500 text-white hover:bg-gray-600 focus:outline-none focus:bg-gray-600 disabled:opacity-50 disabled:pointer-events-none"
            >
                {t('reload')}
            </button>
            <div>
                <div>
                </div>
                <div className='w-full border  m-3 p-3'>

                    <div className='grid grid-cols-4 gap-3' >
                        <div>
                            <div className='font-bold '> { t('name') } </div>
                            <div><small> {t('component')}</small></div>
                        </div>
                        <div>
                            <div><small> {t('URL')} </small></div>
                        </div>
                        <div>
                            <div><small>{t('Arranged')}</small></div>
                        </div>
                        <div>
                            <div><small>{t('Action')}</small></div>
                        </div>

                    </div>
                </div>
                {data != null && data.map((item: any) => {
                    return (
                        <div className='w-full border  m-3 p-3'>

                            <div className='grid grid-cols-4 gap-3'>
                                <div className='flex gap-1'>

                                    <div>
                                        {item.child}
                                        <div className='font-bold '> {item.name} </div>
                                        <div><small>{t(item.component)}</small></div>
                                    </div>
                                    <a onClick={(e) => { addChild(item._id) }}>

                                        <img src='/icons/add.svg' className='cursor-pointer' width={20}></img>

                                    </a>
                                </div>
                                <div>
                                    <div className='text-left'><small> {item.url} </small></div>
                                    {item.arranged}
                                </div>
                                <div>
                                    <div className='flex gap-3'>
                                        <div onClick={(e) => { arrowup(item.id) }}>
                                            <img src='/icons/arrowup.svg' width={20} />

                                        </div>
                                        <div onClick={(e) => { arrowdown(item.id) }}>
                                            <img src='/icons/arrowndown.svg' width={20} />

                                        </div>



                                    </div>
                                </div>
                                <div>
                                    <div className='flex  gap-3'>
                                        <div onClick={(e) => { edited(item.id) }} className='cursor-pointer'  > {t('Edit')}</div>
                                        <div onClick={(e) => { deleted(item.id) }} className='cursor-pointer'> {t('Delete')}</div>
                                    </div>
                                </div>
                            </div>
                            {
                                item.toChild.map((item2: any) => {
                                    return (
                                        <div>
                                            <div className='grid grid-cols-4 gap-3 bg-slate-100 p-4'>
                                                <div className='flex gap-1'>

                                                    <div>
                                                        <div className='font-bold text-red-500 flex  gap-2 '>
                                                            <img src='/icons/enter.svg' width={10}></img>
                                                            {item2.name} </div>
                                                        <div><small>{t(item.component)}</small></div>
                                                    </div>
                                                    <a onClick={(e) => { addChild(item2._id) }}>

                                                        <img src='/icons/add.svg' className='cursor-pointer' width={20}></img>

                                                    </a>
                                                </div>
                                                <div>
                                                    <div className='text-left'><small> {item2.url}</small></div>
                                                </div>
                                                <div>
                                                    <div className='flex gap-3'>
                                                        <div onClick={(e) => { arrowup(item2.id) }}>
                                                            <img src='/icons/arrowup.svg' width={20} />
                                                        </div>
                                                        <div onClick={(e) => { arrowdown(item2.id) }}>
                                                            <img src='/icons/arrowndown.svg' width={20} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='flex gap-3'>
                                                        <div onClick={(e) => { edited(item2.id) }} className='cursor-pointer'  > {t('Edit')}</div>
                                                        <div onClick={(e) => { deleted(item2.id) }} className='cursor-pointer' > {t('Delete')}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                item2.toChild.map((item3: any) => {
                                                    return (
                                                        <div>
                                                            <div className='grid grid-cols-4 gap-3 bg-slate-200 p-4'>
                                                                <div className='flex gap-1'>

                                                                    <div>
                                                                        <div className='font-bold text-red-500 flex gap-2 pr-4 '>
                                                                            <img src='/icons/enter.svg' width={10}></img> {item3.name} </div>
                                                                        <div><small>{t(item3.component)}</small></div>
                                                                    </div>

                                                                </div>
                                                                <div>
                                                                    <div className='text-left'><small> {item3.url}</small></div>
                                                                </div>
                                                                <div>
                                                                    <div className='flex gap-3'>
                                                                        <div onClick={(e) => { arrowup(item3.id) }}>
                                                                            <img src='/icons/arrowup.svg' width={20} />
                                                                        </div>
                                                                        <div onClick={(e) => { arrowdown(item3.id) }}>
                                                                            <img src='/icons/arrowndown.svg' width={20} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='flex gap-3'>
                                                                        <div onClick={(e) => { edited(item3.id) }} className='cursor-pointer'  > {t('Edit')}</div>
                                                                        <div onClick={(e) => { deleted(item3.id) }} className='cursor-pointer' > {t('Delete')}</div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            {/* <div>
                                <pre>{JSON.stringify(item.toChild, null, 2)}</pre>
                            </div> */}
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export function AddMenu({ parent, child = null, showallmenus, edit }: any) {
    let cookie = getCookies();

    const [cookies, setCookie] = useCookies<any>(['token', 'rule']);

    const slectcsc = "";
    const { t } = useTranslation();
    const [component, setComponent] = useState<any>(null);
    const [url, setUrl] = useState<any>(null);
    const [groups, setGroup] = useState<any>(null);
    const [blogs, setBlogs] = useState<any>(null);
    const [gallery, setGallery] = useState<any>(null);
    const [name, setName] = useState<any>(null);

    const [data, setData] = useState<any>(null);


    // Load All Article Group
    const loadGroup = async () => {
        try {
            var path = `${config.url}/v1/dashboard/bloggroup/all`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setGroup(res.data.message)
        } catch (error) {

        }
    }
    // Load All Articles
    const loadArticles = async () => {
        try {
            var path = `${config.url}/v1/dashboard/post/all`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setBlogs(res.data.message)
        } catch (error) {

        }
    }

    // Loadd  All Gallery /v1/dashboard/gallery
    const loadGallery = async () => {
        try {
            var path = `${config.url}/v1/dashboard/gallery/all`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setGallery(res.data.message)
        } catch (error) {

        }
    }

    const ChangeComponent = (com: any) => {
        setComponent(com);
        setUrl('');
        if (com == "blog") {

            loadGroup();
        }
        if (com == "post") {
            loadArticles();
        }
        if (com == "gallery") {
            loadGallery();
        }
        if (com == "url") {

        }
    }
    const ChangeDataGroup = (data: any) => {
        setData(data);
        setUrl('/' + data);

    }
    const ChangeDataBlog = (data: any) => {
        setData(data);
        setUrl('/post/' + data);

    }
    const ChangeDataGallery = (data: any) => {
        setData(data);
        setUrl('/gallery/' + data);

    }
    const ChangeDataUrl = (data: any) => {
        setData(data);
        setUrl(data);

    }
    const save = async () => {
        var mydata = {
            data: data,
            parent: parent,
            child: child,
            component: component,
            url: url,
            name: name

        }

        try {
            var path = `${config.url}/v1/dashboard/menudetail`;

            let res = await axios({
                method: 'post',
                url: path,
                data: mydata,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            showallmenus();
        } catch (error) {
            Swal.fire(t('Fill all the fields'))
        }
    }
    const menuGrouo = [
        {
            name: t('Blog Group'),
            url: '/',
            component: 'blog'

        },
        {
            name: t('Article'),
            url: '/post',
            component: 'post'

        },
        {
            name: t('Gallery'),
            url: '/gallery',
            component: 'gallery'

        },
        {
            name: t('URL'),
            url: '',
            component: 'url'

        }
    ]
    const update = async () => {
        var path = `${config.url}/v1/dashboard/menudetail/${edit}`;
        try {
            let res = await axios({
                method: 'put',
                url: path,
                data: {
                    name: name,
                    url: url,
                    component: component,
                    data: data
                },
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            showallmenus();
        } catch (error) {
            Swal.fire(t('Fill all the fields'))

        }

    }
    const loadmenu = async () => {
        try {
            var path = `${config.url}/v1/dashboard/menudetail/showedit/${edit}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setComponent(res.data.message.component);
            setUrl(res.data.message.url);
            setName(res.data.message.name);
            setData(res.data.message.data);
            res.data.message.component == "post" && loadArticles();
            res.data.message.component == "blog" && loadGroup();
            res.data.message.component == "gallery" && loadGallery();

        } catch (error) {

        }
    }
    useEffect(() => {
        if (edit != null) {
            loadmenu();
        }
    }, []);
    return (
        <div>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
                <div>
                    <label>{t('name')}</label>
                    <input
                        defaultValue={name}
                        className='bg-slate-400 rounded p-2 w-full text-white'
                        type='text'
                        onChange={(e) => { setName(e.target.value) }}
                    ></input>
                </div>
                <div>
                    <label>{t('component')}</label>
                    <div>
                        <select
                            className='bg-slate-400 rounded p-2 w-full'
                            value={component}
                            onChange={(e: any) => { ChangeComponent(e.target.value) }}
                        >
                            <option></option>
                            {
                                menuGrouo.map((i: any) => {
                                    return (
                                        <option value={i.component}>{i.name}</option>
                                    )
                                })
                            }

                        </select>
                    </div>
                </div>
                <div>
                    <div>
                        <label>{t('url')}</label>
                        <input
                            disabled
                            dir='ltr'
                            className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            value={url} ></input>
                    </div>
                </div>

                <div>
                    {component == "blog" && <div>
                        <label>{t('Select Group')}</label>
                        {groups != null &&
                            <div>
                                <select
                                    defaultValue={data}
                                    className='bg-slate-400 rounded p-2 w-full'
                                    onChange={(e: any) => { ChangeDataGroup(e.target.value) }}
                                >
                                    <option></option>
                                    {
                                        groups.map((i: any) => {
                                            return (
                                                <option value={i.url}>{i.name}</option>
                                            )
                                        })
                                    }

                                </select>

                            </div>
                        }
                    </div>

                    }
                    {component == "post" && <div>
                        <label>{t('Select Article')}</label>
                        {blogs != null &&
                            <div>
                                <select
                                    defaultValue={data}
                                    className='bg-slate-400 rounded p-2 w-full'
                                    onChange={(e: any) => { ChangeDataBlog(e.target.value) }}
                                >
                                    <option></option>
                                    {
                                        blogs.map((i: any) => {
                                            return (
                                                <option value={i.url}>{i.toGroup.name} - {i.name} </option>
                                            )
                                        })
                                    }

                                </select>

                            </div>
                        }
                    </div>

                    }
                    {component == "gallery" && <div>
                        <label>{t('Select Gallery')}</label>
                        {gallery != null &&
                            <div>
                                <select
                                    defaultValue={data}
                                    className='bg-slate-400 rounded p-2 w-full text-white'
                                    onChange={(e: any) => { ChangeDataGallery(e.target.value) }}
                                >
                                    <option></option>
                                    {
                                        gallery.map((i: any) => {
                                            return (
                                                <option className='text-white' value={i.url}>{i.name} </option>
                                            )
                                        })
                                    }

                                </select>

                            </div>
                        }

                    </div>

                    }
                    {component == "url" && <div>
                        <label>{t('Type URL')}</label>
                        <input
                            defaultValue={data}
                            className='bg-slate-400 rounded p-2 w-full text-white text-left' dir='ltr'
                            type='text' onChange={(e) => { ChangeDataUrl(e.target.value) }}></input>

                    </div>

                    }
                </div>
            </div>
            <div className='mt-3'>
                {
                                 edit!=null &&   <div className='container p-4 w-[200px] m-auto'>
                                    <ImageUploader id={edit} component={'MenuDetail'}></ImageUploader>
                                 </div>

                }
                <div className='w-full flex gap-1'>
                    {
                        edit == null ?
                            <button
                                onClick={(e) => {
                                    save();
                                }}
                                type="button"
                                className="py-3 m-auto  px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-500 text-white hover:bg-gray-600 focus:outline-none focus:bg-gray-600 disabled:opacity-50 disabled:pointer-events-none"
                            >
                                {t('save')}
                            </button>
                            :
                            <button
                                onClick={(e) => {
                                    update();
                                }}
                                type="button"
                                className="py-3 m-auto  px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-500 text-white hover:bg-gray-600 focus:outline-none focus:bg-gray-600 disabled:opacity-50 disabled:pointer-events-none"
                            >
                                {t('update')}
                            </button>
                    }

                    <button
                        onClick={(e) => {
                            showallmenus();
                        }}
                        type="button"
                        className="py-3 m-auto  px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-700 text-white hover:bg-gray-600 focus:outline-none focus:bg-gray-600 disabled:opacity-50 disabled:pointer-events-none"
                    >
                        {t('Cancel')}
                    </button>

                </div>
            </div>
        </div>
    )
}