import { useTranslation } from 'react-i18next';

export default function BreadCrump({ data }:any) {
    const { t } = useTranslation();

    return (
        <>
            <ol className="flex items-center whitespace-nowrap mb-5 directions " >
                {
                    data.map((i:any)=>{
                        return(
                            i.url == null ?
                            <li className="inline-flex items-center text-sm font-semibold text-gray-800 truncate dark:text-neutral-200" aria-current="page">
                          { t(i.name) }
                        </li>
                            :
                            <li className="inline-flex items-center">
                            <a href={i.url} className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:text-neutral-500 dark:hover:text-blue-500 dark:focus:text-blue-500" >
        
                                { t(i.name) }
                            </a>
                            <svg className="shrink-0 mx-2 size-4 text-gray-400 dark:text-neutral-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m9 18 6-6-6-6"></path>
                            </svg>
                        </li>
                        )
                    })
                }
              
               
              
            </ol>
        </>
    )
}