import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import NavCanvas from "./nav";
const darkTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

export default function DashboardLayout() {
    const availableLanguages = ['fa', 'en'];
    const changeLanguage = (lng: any) => {
        i18next.changeLanguage(lng);
    };

    const { t } = useTranslation();

    return (
        <div className="App" dir={t('dir')}>
            <div dir={t('dir')} >
                <header className="">

                    <div style={{ backgroundColor: '#E4E6EB', height: '90vh' }}>
                        <div className="grid grid-cols-12">

                            <div className="col-span-12 sm:col-span-12 p-1 bg-gray-500 pr-4 pl-4 pt-3 pb-3">
                                <div className="grid grid-cols-3 m-auto w-full">
                                    <div className="col-span-1 flex gap-4">
                                        <NavCanvas></NavCanvas>
                                        <div className="mt-2" >
                                            <a href="/logout" className="flex gap-3  pr-3 pl-3 bg-red-900 w-[90px] h-[30px] rounded-[10px] mr-4 ml-4 ">
                                                <img src="/icons/logout.svg" className="float-end" width={25}></img>
                                                <a className="text-white">خروج</a>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-span-1 ">


                                    </div>
                                    <div className="col-span-1  gap-2  pt-4 ">

                                        <a href="/dashboard">
                                            <img src="/icons/logo.svg" className="float-start" width={120}></img>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div className="col-span-12 sm:col-span-12 p-1 bg-white pr-4 pl-4 pt-3 pb-3">

                                <div className="container m-auto">

                                    <Outlet></Outlet>
                    
                                </div>
                                <div className="bg-slate-700 w-[100%] h-[24px] text-white">
                                    Tiss CMS 2.1 - React Edition

                    </div>
                            </div>
                        </div>
                    </div>
                  
                </header>

            </div>

        </div>
    )
}