export const  DashboardMenu=[
    {
        name:'Dashboard',
        url:'/dashboard',
        icon:'/icons/dashboard.svg',
        show:false,
        color:'blue',
        sub:[ ]

    },
    {
        name:'Blogs',
        url:'/dashboard/Blog',
        icon:'/icons/blog.svg',
        show:true,
        color:'#CCD5AE',
        sub:[
        
            {
                name:'Article List',
                url:'/dashboard/Blog/Articles',
             
            },
            {
                name:'Blog Group',
                url:'/dashboard/Blog/list',
             
            }
        ]

    },
    {
        name:'Setting',
        url:'/dashboard/Setting',
        icon:'/icons/setting.svg',
        show:true,
        color:'#E0E5B6',
        sub:[
            {
                name:'General',
                url:'/dashboard/Setting/general',
             
            },
            {
                name:'Social Network',
                url:'/dashboard/Setting/socialnetwork',
             
            },
            {
                name:'Contact',
                url:'/dashboard/Setting/contact',
             
            }
        ]

    },
    {
        name:'User Manegment',
        url:'/dashboard/User',
        icon:'/icons/user.svg',
        show:true,
        color:'#E8B86D',
        sub:[
            {
                name:'User List',
                url:'/dashboard/User/list',
             
            }
            
        ]

    },
    {
        name:'Feature',
        url:'/dashboard/Feature',
        icon:'/icons/feture.svg',
        show:true,
        color:'#E8B86D',
        sub:[
            {
                name:'Menus',
                url:'/dashboard/Feature/Menus',
             
            }
            
        ]

    },
    {
        name:'Gallery',
        url:'/dashboard/Gallery',
        icon:'/icons/gallery.svg',
        show:true,
        color:'#FAEDCE',
        sub:[
            {
                name:'Sliders',
                url:'/dashboard/Gallery/list',
             
            },
            {
                name:'Gallery',
                url:'/dashboard/Gallery/gallery',
             
            }
            
        ]

    },
    // {
    //     name:'Menus',
    //     url:'/dashboard/Menus',
    //     icon:'/icons/menu.svg',
    //     show:true,
    //     color:'#FEFAE0',
    //     sub:[
    //         {
    //             name:'Menu List',
    //             url:'/dashboard/Menus/list',
             
    //         }
            
    //     ]

    // },
    {
        name:'About',
        url:'/dashboard/about',
        icon:'/icons/about.svg',
        show:true,
        color:'#CEDF9F',
        sub:[ ]

    },
]