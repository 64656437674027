import { config } from "../data/config";

const Gridoption = (i:any) => {

    switch (i) {
        case 1:
            var x = 'sm:col-span-3 col-span-12';
            break;
        case 2:
            var x = 'sm:col-span-6 col-span-12';
            break;
        case 3:
            var x = 'sm:col-span-12 col-span-12';
            break;
        default:
            var x = 'sm:col-span-1 col-span-12';
    }
    console.log(i);

    console.log(x);
    return x;

}
export default function ShowArticle({ data }:any) {
    return (

        <div className="grid grid-cols-12 ">

            {
                data.map((item:any) => {
                    return (
                        <div className={Gridoption(item.cols)}>
                            {item.mode == 'text' && TextShow(item)}
                            {item.mode == 'heading' && HeadeingShow(item)}
                            {item.mode == 'image' && ImageShow(item)}


                        </div>
                    )
                })
            }
        </div>
    )
}
function TextShow(data:any) {
    return (<div>
        <div className='w-[100%] text-left ' dangerouslySetInnerHTML={{ __html: data.data.html }} />

    </div>)
}
function HeadeingShow(data:any) {
    return (<div>

        {data.data != undefined &&
            <div className="text-left">

                {data.data.mode == 'h1' && <h1 className='text-[24px]'>{data.data.text}</h1>}
                {data.data.mode == 'h2' && <h2 className='text-[20px]'>{data.data.text}</h2>}
                {data.data.mode == 'h3' && <h3 className='text-[18px]'>{data.data.text}</h3>}
                {data.data.mode == 'h4' && <h4 className='text-[16px]'>{data.data.text}</h4>}
                {data.data.mode == 'h5' && <h5 className='text-[14px]'>{data.data.text}</h5>}
                {data.data.mode == 'h6' && <h6 className='text-[12px]'>{data.data.text}</h6>}
            </div>

        }

    </div>)
}
function ImageShow(data:any) {
    return (<div>
        {data.toimage.map((i:any) => {
            return (
                <div>
                    <img src={config.url+i.url} className="m-auto"></img>

                </div>
            )
        })}


    </div>)
}