import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";

//i18n 
import './i18n';  // Import i18n configuration


import { useCookies } from 'react-cookie';
import DashboardLayout from './layout/authlayout';
import Dashboard from './componenets/dashboard';
import AboutUs from './componenets/About/About';
import { useLocation } from 'react-router-dom';



import AuthPage from './componenets/Auth/AuthPage';
// Blog
import IndexBlog from './componenets/Blog/indexBlog';
import ListBlog from './componenets/Blog/ListBlog';
import ListArticles from './componenets/Blog/ListArticles';
import EditBlog from './componenets/Blog/editBlog';

// Setting
import IndexSetting from './componenets/Setting/indexSetting';
import SettingPage from './componenets/Setting/general';
import SocialPage from './componenets/Setting/social';
import ContactPage from './componenets/Setting/contact';

//Gallery
import IndexGallery from './componenets/Gallery/indexGallery';
import GalleryList from './componenets/Gallery/listGallery';
import EditGallery from './componenets/Gallery/editGallery';
import EditDetail from './componenets/Gallery/DetailImage';
import ListSlider from './componenets/Gallery/listSlider';
import EditSlider from './componenets/Gallery/editSlider';

// User
import IndexUser from './componenets/User/indexUser';
import UserList from './componenets/User/UserList';
import EdtiUser from './componenets/User/EdtiUser';
// Menus 
import MenuGroup from './componenets/Menus/MenuGroup';
import MenuDetail from './componenets/Menus/MenuDetail';
// Logout
import { LogOut } from './logout/LogOut';
import "preline/preline";
import { IStaticMethods } from "preline/preline";
import EditPost from './componenets/Blog/editPost';
import FoodMenu from './pages';
import FeatureIndex from './componenets/Feature/featureIndex';

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

function App() {
  const [cookies] = useCookies(['token']);
  const token = cookies.token; // Retrieve the token
  //const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/logout" element={<LogOut />} />

        <Route path="/" >
          <Route path="/" element={<FoodMenu />} />
        </Route>
        <Route path="/login">
          <Route index element={!token ? <AuthPage /> : <Navigate to="/dashboard" />} />
        </Route>
        <Route
          path="/dashboard"
          element={token ? <DashboardLayout /> : <Navigate to="/login" />}>
          <Route index element={<Dashboard />} />
          <Route path='about' element={<AboutUs />} />
          <Route path="Blog"  >
            <Route index element={<IndexBlog />} />
            <Route path="list" element={<ListBlog />} />
            <Route path="GroupEdit/:Id" element={<EditBlog />} />
            <Route path="Articles" element={<ListArticles />} />
            <Route path="EditPost/:Id" element={<EditPost />} />
          </Route>
          <Route path="Setting"  >
            <Route index element={<IndexSetting />} />
            <Route path="general" element={<SettingPage />} />
            <Route path="socialnetwork" element={<SocialPage />} />
            <Route path="contact" element={<ContactPage />} />
          </Route>
          <Route path="Gallery"  >
            <Route index element={<IndexGallery />} />
            <Route path="gallery" element={<GalleryList />} />
            <Route path="gallery/:Id" element={<EditGallery />} />
            <Route path="detailimage/:Id" element={<EditDetail />} />
            <Route path="list" element={<ListSlider />} />
            <Route path="list/:Id" element={<EditSlider />} />
          </Route>
          <Route path="User"  >
            <Route index element={<IndexUser />} />
            <Route path="list" element={<UserList />} />
            <Route path="list/:Id" element={<EdtiUser />} />
          </Route>
          <Route path="Feature"  >
            <Route index element={<FeatureIndex />} />
            <Route path="Menus" element={<MenuGroup />} />
            <Route path="Menus/:Id" element={<MenuDetail />} />

          </Route>
        </Route>

      </Routes>


    </BrowserRouter>

  );
}

export default App;
