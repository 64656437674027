
import '../Main.css';

import axios from "axios";
import React, { useEffect } from "react";
import { config } from "../data/config";
import { MenuitemTop } from "./menuitem";
import ShowArticle from "./showarticle";
import CarsoulSlider from "./carousl";
import FooterPage from './footer';
export default function FoodMenu() {
    const [data, setData] = React.useState<any>(null);
    const [show, setShow] = React.useState<any>(0);

    const load = async () => {
        try {
            var path = `${config.url}/v1/header`;

            let res = await axios({
                method: 'get',
                url: path,

            });
            document.title = res.data.setting.title;

            setData(res.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        load();

    }, []);
    return (
        <div>
            
            {
                data != null &&
                <div>
                    <div className="w-[100%] bg-white shadow min-h-[80px] fixed h-[80px] z-50 hidden sm:block" dir='ltr'>
                        <div className="grid grid-cols-1 sm:grid-cols-3 p-6 container">
                            <div className="col-span-1 sm:col-span-2 pt-5">
                                <ul className="flex">
                                    {
                                        MenuitemTop.map((item) => {
                                            return (
                                                <li className="mr-6">
                                                    <a   className="text-black hover:text-blue-800 font-black" href={item.href} >{item.name}</a>
                                                </li>

                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="container">
                                <img width={100} className="float-end" src={`${config.url}/${data.setting.toImage.url}`}></img>
                            </div>
                        </div>

                    </div>
                    <div id='home' className="pt-11">
                        <CarsoulSlider data={data.sliders.toImage}></CarsoulSlider>
                    </div>
                    <div id='foodmenu' className="mt-5">
                        <div className="  dark:border-neutral-700">
                            <div className="  m-auto w-[300px] sm:w-[500px] sm:grid-cols-3 grid-cols-1 grid ">
                                {
                                    data.grouplist.map((i:any, index:any) => {
                                        return (
                                            <a href='#foodmenu' onClick={(e) => { setShow(index) }} className={` ${index == show && `border-[4px] bg-slate-400 `}  cursor-pointer  text-center p-3 rounded-[29px]`}>
                                                <div>
                                                    {i.toimage != null &&
                                                        <img className="m-auto " alt={i.name} src={config.url + i.toimage.url}></img>
                                                    }
                                                </div>

                                            </a>
                                        )
                                    })}

                            </div>
                        </div>

                        <div className="mt-3 container m-auto p-4" >
                            {
                                data.grouplist.map((i:any, index:any) => {
                                    return (
                                        index == show &&
                                        <div id={i.url} role="tabpanel" aria-labelledby={i.url}>
                                            <div>
                                                <h3 className="text-[24px] text-left">{i.name}</h3>
                                            </div>
                                            <hr ></hr>
                                            <div>
                                                {
                                                    i.toArticles.map((item:any, index:any) => {
                                                        return (
                                                            index % 2 == 0 ?
                                                                <div>
                                                                    <div className="grid grid-cols-1 sm:grid-cols-4 card shadow mt-4 p-5">
                                                                        <div className="col-span-1">
                                                                            {item.toimage != null &&
                                                                                <div>
                                                                                    <img src={config.url + item.toimage.url}></img>
                                                                                </div>

                                                                            }

                                                                        </div>
                                                                        <div className="col-span-1 sm:col-span-3 p-5">
                                                                            <h5 className="text-[24px] text-left">{item.name}</h5>

                                                                            <ShowArticle data={item.tocomponent}></ShowArticle>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                :
                                                                <div className='bg-slate-300'>
                                                                    <div className="grid grid-cols-1 sm:grid-cols-4 card shadow mt-4 p-5">
                                                                        <div className="col-span-1 sm:col-span-3 p-5">
                                                                            <h5 className="text-[24px] text-left">{item.name}</h5>

                                                                            <ShowArticle data={item.tocomponent}></ShowArticle>
                                                                        </div>
                                                                        <div className="col-span-1">
                                                                            {item.toimage != null &&
                                                                                <div>
                                                                                    <img src={config.url + item.toimage.url}></img>
                                                                                </div>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )
                                                    }
                                                    )
                                                }


                                            </div>

                                        </div>
                                    )
                                })}



                        </div>
                    </div>

                    <div id="about" className="bg-zinc-100 min-h-[100px] p-3  ">
                        <div className="container m-auto">
                            <ShowArticle data={data.AboutText.tocomponent}></ShowArticle>
                        </div>

                    </div>
                </div>
            }

            {
                data!=null && <FooterPage data={data}></FooterPage>
            }

        </div >

    )
}