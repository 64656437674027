import BreadCrump from '../tools/breadcrump';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { config } from "../../data/config";
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useState } from "react";
import ImageUploader from '../tools/uploaderImage';
import axios from 'axios';
import { getCookies } from "cookies-next";
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
export default function SettingPage() {
    const { t } = useTranslation();

    const [data, setData] = React.useState<any>(null);
    const [key, setKey] = useState<any>(1);
    const [error, setError] = useState<any>(null);
    let cookie = getCookies();

    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none shadow";
    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },

        {
            name: 'Setting',
            url: '/dashboard/setting'
        },
        {
            name: 'General',
            url: null
        },
    ]
    const load = async () => {
        try {
            var path = `${config.url}/v1/dashboard/setting`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setData(res.data.message)
        } catch (error) {

        }
    }
    const save = async () => {
        try {
            var path = `${config.url}/v1/dashboard/setting/${data.id}`;

            let res = await axios({
                method: 'put',
                data:data,
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            Swal.fire(t('Saved'));
        } catch (error) {

        }
    }
    useEffect(() => {
        load();
    }, []);
    return (
        <div className='directions'>
            <div className="text-left  bg-white  p-3 m-4   h-[100vh] mb-5 ">


                <BreadCrump data={breadcrump}></BreadCrump>
                <div>
                    <form>
                        <div className="p-3 directions rounded-[10px] ">
                            <h4 className="text-black text-[20px] font-bold ">{t('General Setting')}</h4>
                            <hr />

                        </div>
                    </form>
                </div>
                {
                    data != null && <div className='grid grid-cols-2 p-4 '>
                        <div>
                            <form key={key}>
                                <div className="max-w-sm space-y-3">
                                    <label className="">{t('website Name')}   </label>
                                    <input

                                        defaultValue={data.name}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className="">{t('Title')}   </label>
                                    <input

                                        defaultValue={data.title}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Title"
                                        onChange={(e) => setData({ ...data, title: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="">{t('Keywords')}   </label>
                                    <textarea
                                        defaultValue={data.keywords}


                                        className={inputstyle}
                                        onChange={(e) => setData({ ...data, keywords: e.target.value })}
                                        placeholder="..." />
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className=""> {t('Description')}   </label>
                                    <textarea
                                        defaultValue={data.description}

                                        className={inputstyle}
                                        onChange={(e) => setData({ ...data, description: e.target.value })}
                                        placeholder="..." />
                                </div>
                                <div className='text-center w-[100%] pt-3 '>
                                    <button
                                    onClick={(e)=>{save()}}
                                     type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-transparent bg-gray-700 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 w-[100px] text-center m-auto disabled:pointer-events-none">
                                         {t('Save')} 
                                    </button>
                                </div>
                            </form>
                        </div>

                        {data != null && <div>
                            <div className='directions'>   {t('Logo Upload')} </div>
                            <ImageUploader id={data.id} component={'Post'}></ImageUploader>

                        </div>}


                    </div>
                }


            </div>

        </div>
    )
}