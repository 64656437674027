import { useParams } from 'react-router-dom';
import { config } from "../../data/config";
import { useEffect } from 'react';
import { getCookies } from "cookies-next";
import { useCookies } from 'react-cookie';
import axios from "axios";
import { useState } from "react";
import BreadCrump from "../tools/breadcrump"

import * as React from 'react';
import TissEditor from '../Editor/TissEditor';
import ImageUploader from '../tools/uploaderImage';
import { useTranslation } from 'react-i18next';
export default function EditBlog() {
    const { t } = useTranslation();

    let cookie = getCookies();

    let { Id } = useParams();
    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [key, setKey] = useState<any>(1);
    const [error, setError] = useState<any>(null);

    const [data, setData] = React.useState<any>(null);
    const save = () => {

    }
    const load = async () => {
        try {
            var path = `${config.url}/v1/dashboard/bloggroup/${Id}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setData(res.data.message)
        } catch (error) {

        }
    }
    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none";
    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },
        {
            name: 'Blog',
            url: '/dashboard/Blog'
        },
        {
            name: 'Group List',
            url: '/dashboard/Blog/list'
        },
        {
            name: 'Edit Group',
            url:null
        }
    ]
    useEffect(() => {
        load();
    }, []);
    return (
        <div>
                        <BreadCrump data={breadcrump}></BreadCrump>

           
            {
                data != null && <div className='grid grid-cols-1 sm:grid-cols-5'>
                    <div >

                        <div className="p-3 bg-gray-500 rounded-[10px] p-5">
                            <h4 className="text-white text-[20px] font-bold ">{ t('Edit Category') }</h4>
                            <hr />
                            <form key={key}>
                                <div className="max-w-sm space-y-3">
                                    <label className="text-white">{ t('Name') } </label>
                                    <input
                                        defaultValue={data.name}
                                        type="text"
                                        className={`${inputstyle}     ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="text-white"> Slug </label>
                                    <input
                                        type="text"
                                        defaultValue={data.url}
                                        className={`${inputstyle}       ${error != null && error['url'] != undefined && `bg-red-100`}  `}
                                        placeholder="Slug"
                                        onChange={(e) => setData({ ...data, url: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['url']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="text-white">{ t('Keywords') }  </label>
                                    <textarea
                              
                                        defaultValue={data.keywords}


                                        className={inputstyle}
                                        onChange={(e) => setData({ ...data, keywords: e.target.value })}
                                        placeholder="..." />
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="text-white">{ t('Description') }   </label>
                                    <textarea
                                  
                                        defaultValue={data.description}

                                        className={inputstyle}
                                        onChange={(e) => setData({ ...data, description: e.target.value })}
                                        placeholder="..." />
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <button type="button"
                                        onClick={(e) => { save() }}
                                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:focus:bg-white/20 dark:focus:text-white"
                                    >{ t('Update Category') } 
                                        
                                    </button>

                                </div>
                            </form>
                            <div className='mt-3'>
                            <div className="text-white text-left">{ t('Thumpnail') }  </div>

                            <ImageUploader id={data.id} component={'BlogGroup'}></ImageUploader>

                                </div>

                           
                        </div>
                    </div>
                    <div className='col-span-1 sm:col-span-4'>
                        <TissEditor component='bloggroup' parent={data.id} />
                    </div>
                </div>
            }

        </div>
    )
}