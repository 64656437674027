import BreadCrump from '../tools/breadcrump';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { config } from "../../data/config";
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useState } from "react";
import ImageUploader from '../tools/uploaderImage';
import axios from 'axios';
import { getCookies } from "cookies-next";
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';


export default function ContactPage() {
    const { t } = useTranslation();

    const [data, setData] = React.useState<any>(null);
    const [key, setKey] = useState<any>(1);
    const [error, setError] = useState<any>(null);
    let cookie = getCookies();

    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none shadow";
    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },

        {
            name: 'Setting',
            url: '/dashboard/setting'
        },
        {
            name: 'Contact',
            url: null
        },
    ]
    const load = async () => {
        try {
            var path = `${config.url}/v1/dashboard/contact`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setData(res.data.message)
        } catch (error) {

        }
    }
    const save = async () => {
        try {
            var path = `${config.url}/v1/dashboard/contact/${data.id}`;

            let res = await axios({
                method: 'put',
                data: data,
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            Swal.fire('Saved');
        } catch (error) {

        }
    }
    useEffect(() => {
        load();
    }, []);
    return (
        <div>
            <div className="  directions bg-white  p-3 m-4   h-[100vh] mb-5 ">


                <BreadCrump data={breadcrump}></BreadCrump>
                <div>

                    <div className="p-3  rounded-[10px] ">
                        <h4 className="text-black text-[20px] font-bold ">{ t('Contact Us')}</h4>
                        <hr />

                    </div>
                    <button
                        onClick={(e) => { save() }}
                        type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-transparent bg-gray-700 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 w-[100px] text-center m-auto disabled:pointer-events-none">
                         { t('Save')}
                    </button>
                </div>
                {
                    data != null && <div className=' p-4 '>
                        <div className="w-full bg-white rounded-lg shadow-md ">
                            <div className="border-b border-gray-200 px-4 ">
                                <nav className="flex gap-x-2" aria-label="Tabs" role="tablist" aria-orientation="horizontal">
                                    <button type="button" className="hs-tab-active:font-semibold hs-tab-active:border-blue-600 hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500 dark:focus:text-blue-500 active" id="basic-tabs-item-1" aria-selected="true" data-hs-tab="#basic-tabs-1" aria-controls="basic-tabs-1" role="tab">
                                    { t('Address')}  
                                    </button>
                                    <button type="button" className="hs-tab-active:font-semibold hs-tab-active:border-blue-600 hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500 dark:focus:text-blue-500" id="basic-tabs-item-2" aria-selected="false" data-hs-tab="#basic-tabs-2" aria-controls="basic-tabs-2" role="tab">
                                    { t('Phone Number')}    
                                    </button>
                                    <button type="button" className="hs-tab-active:font-semibold hs-tab-active:border-blue-600 hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500 dark:focus:text-blue-500" id="basic-tabs-item-3" aria-selected="false" data-hs-tab="#basic-tabs-3" aria-controls="basic-tabs-3" role="tab">
                                    { t('E-mails')}      
                                    </button>
                                    <button type="button" className="hs-tab-active:font-semibold hs-tab-active:border-blue-600 hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500 dark:focus:text-blue-500" id="basic-tabs-item-3" aria-selected="false" data-hs-tab="#basic-tabs-4" aria-controls="basic-tabs-4" role="tab">
                                    { t('Website')}     
                                    </button>
                                </nav>
                            </div>

                            <div className="mt-3 p-4">
                                <div id="basic-tabs-1" role="tabpanel" aria-labelledby="basic-tabs-item-1">
                                    <div>
                                        <div className='grid grid-cols-4 gap-4 '>
                                            <div className="max-w-sm space-y-3 ">
                                                <div className="bg-slate-500 text-white p-2">   { t('Label Address')}  1 </div>
                                                <input

                                                    defaultValue={data.laddress1}
                                                    type="text"
                                                    className={`${inputstyle}  shadow   `}
                                                    placeholder="Name"
                                                    onChange={(e) => setData({ ...data, laddress1: e.target.value })}
                                                />
                                                <div className="mt-4 bg-slate-500 text-white p-2">{ t('Postal code')}   </div>
                                                <input

                                                    defaultValue={data.pbox1}
                                                    type="text"
                                                    className={`${inputstyle}  shadow   `}
                                                    placeholder="Name"
                                                    onChange={(e) => setData({ ...data, pbox1: e.target.value })}
                                                />
                                            </div>
                                            <div className=" col-span-3">
                                                <div className="bg-slate-500 text-white p-1"> { t('Address')}   1 </div>
                                                <textarea
                                                    defaultValue={data.address1}
                                                    className={`${inputstyle}  shadow  w-[100%] `}
                                                    placeholder="Name"
                                                    rows={7}
                                                    onChange={(e) => setData({ ...data, address1: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='grid grid-cols-4 gap-4 '>
                                            <div className="max-w-sm space-y-3 ">
                                                <div className="bg-slate-500 text-white p-2"> { t('Label Address')} 2 </div>
                                                <input

                                                    defaultValue={data.laddress1}
                                                    className={`${inputstyle}  shadow   `}
                                                    placeholder="Name"
                                                    onChange={(e) => setData({ ...data, laddress2: e.target.value })}
                                                />
                                                <div className="mt-4 bg-slate-500 text-white p-2">  { t('Postal code')} </div>
                                                <input

                                                    defaultValue={data.pbox1}
                                                    type="text"
                                                    className={`${inputstyle}  shadow   `}
                                                    placeholder="Name"
                                                    onChange={(e) => setData({ ...data, pbox2: e.target.value })}
                                                />
                                            </div>
                                            <div className=" col-span-3">
                                                <div className="bg-slate-500 text-white p-1"> { t('Address')}  2 </div>
                                                <textarea
                                                    defaultValue={data.address2}
                                                    className={`${inputstyle}  shadow  w-[100%] `}
                                                    placeholder="Name"
                                                    rows={7}
                                                    onChange={(e) => setData({ ...data, address2: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='grid grid-cols-4 gap-4 '>
                                            <div className="max-w-sm space-y-3 ">
                                                <div className="bg-slate-500 text-white p-2"> { t('Label Address')}  3 </div>
                                                <input

                                                    defaultValue={data.laddress1}
                                                    className={`${inputstyle}  shadow   `}
                                                    placeholder="Name"
                                                    onChange={(e) => setData({ ...data, laddress3: e.target.value })}
                                                />
                                                <div className="mt-4 bg-slate-500 text-white p-2">  { t('Postal code')}   </div>
                                                <input

                                                    defaultValue={data.pbox1}
                                                    className={`${inputstyle}  shadow   `}
                                                    placeholder="Name"
                                                    onChange={(e) => setData({ ...data, pbox3: e.target.value })}
                                                />
                                            </div>
                                            <div className=" col-span-3">
                                                <div className="bg-slate-500 text-white p-1"> { t('Address')}    3 </div>
                                                <textarea
                                                    defaultValue={data.address3}
                                                    className={`${inputstyle}  shadow  w-[100%] `}
                                                    placeholder="Name"
                                                    rows={7}
                                                    onChange={(e) => setData({ ...data, address3: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='grid grid-cols-4 gap-4 '>
                                            <div className="max-w-sm space-y-3 ">
                                                <div className="bg-slate-500 text-white p-2">{ t('Label Address')}    4 </div>
                                                <input

                                                    defaultValue={data.laddress1}
                                                    type="text"
                                                    className={`${inputstyle}  shadow   `}
                                                    placeholder="Name"
                                                    onChange={(e) => setData({ ...data, laddress4: e.target.value })}
                                                />
                                                <div className="mt-4 bg-slate-500 text-white p-2">{ t('Postal code')}   </div>
                                                <input

                                                    defaultValue={data.pbox3}
                                                    type="text"
                                                    className={`${inputstyle}  shadow   `}
                                                    placeholder="Name"
                                                    onChange={(e) => setData({ ...data, pbox3: e.target.value })}
                                                />
                                            </div>
                                            <div className=" col-span-3">
                                                <div className="bg-slate-500 text-white p-1"> { t('Address')}   3 </div>
                                                <textarea
                                                    defaultValue={data.address3}
                                                    className={`${inputstyle}  shadow  w-[100%] `}
                                                    placeholder="Name"
                                                    rows={7}
                                                    onChange={(e) => setData({ ...data, address3: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="basic-tabs-2" className="hidden" role="tabpanel" aria-labelledby="basic-tabs-item-2">
                                    <div className='grid sm:grid-cols-4 grid-cols-1 gap-4'>
                                        <div className=" mt-1 ">
                                            <div className="bg-slate-500 text-white p-1"> { t('Label Phone')}   1 </div>
                                            <input
                                                defaultValue={data.lphone1}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Name"
                                                onChange={(e) => setData({ ...data, lphone1: e.target.value })}
                                            />
                                            <div className="bg-slate-500 text-white p-1"> { t('Phone')}   1 </div>
                                            <input
                                                defaultValue={data.phone1}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Phone"
                                                onChange={(e) => setData({ ...data, phone1: e.target.value })}
                                            />
                                        </div>

                                        <div className=" mt-1 ">
                                            <div className="bg-slate-500 text-white p-1">  { t('Label Phone')}   2 </div>
                                            <input
                                                defaultValue={data.lphone2}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Name"
                                                onChange={(e) => setData({ ...data, lphone2: e.target.value })}
                                            />
                                            <div className="bg-slate-500 text-white p-1">{ t('Phone')}   2 </div>
                                            <input
                                                defaultValue={data.phone2}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Phone"
                                                onChange={(e) => setData({ ...data, phone2: e.target.value })}
                                            />
                                        </div>

                                        <div className=" mt-1 ">
                                            <div className="bg-slate-500 text-white p-1">  { t('Label Phone')}   3</div>
                                            <input
                                                defaultValue={data.lphone3}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Name"
                                                onChange={(e) => setData({ ...data, lphone3: e.target.value })}
                                            />
                                            <div className="bg-slate-500 text-white p-1">   { t('Phone')}  2 </div>
                                            <input
                                                defaultValue={data.phone3}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Phone"
                                                onChange={(e) => setData({ ...data, phone3: e.target.value })}
                                            />
                                        </div>

                                        <div className=" mt-1 ">
                                            <div className="bg-slate-500 text-white p-1">  { t('Label Phone')}  4</div>
                                            <input
                                                defaultValue={data.lphone4}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Name"
                                                onChange={(e) => setData({ ...data, lphone4: e.target.value })}
                                            />
                                            <div className="bg-slate-500 text-white p-1"> { t('Phone')}  4 </div>
                                            <input
                                                defaultValue={data.phone4}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Phone"
                                                onChange={(e) => setData({ ...data, phone4: e.target.value })}
                                            />
                                        </div>



                                    </div>
                                </div>
                                <div id="basic-tabs-3" className="hidden" role="tabpanel" aria-labelledby="basic-tabs-item-3">
                                    <div className='grid sm:grid-cols-4 grid-cols-1 gap-4'>

                                        <div className=" mt-1 ">
                                            <div className="bg-slate-500 text-white p-1">  { t('Label E-mail')}   1</div>
                                            <input
                                                defaultValue={data.lemail1}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Name"
                                                onChange={(e) => setData({ ...data, lemail1: e.target.value })}
                                            />
                                            <div className="bg-slate-500 text-white p-1"> { t('E-mail')}     1</div>
                                            <input
                                                defaultValue={data.email1}
                                                type="email"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="E-mail"
                                                onChange={(e) => setData({ ...data, email1: e.target.value })}
                                            />
                                        </div>

                                        <div className=" mt-1 ">
                                            <div className="bg-slate-500 text-white p-1">  { t('Label E-mail')}   2</div>
                                            <input
                                                defaultValue={data.lemail2}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Name"
                                                onChange={(e) => setData({ ...data, lemail2: e.target.value })}
                                            />
                                            <div className="bg-slate-500 text-white p-1">   { t('E-mail')}  1</div>
                                            <input
                                                defaultValue={data.email2}
                                                type="email"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="E-mail"
                                                onChange={(e) => setData({ ...data, email2: e.target.value })}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div id="basic-tabs-4" className="hidden" role="tabpanel" aria-labelledby="basic-tabs-item-4">
                                    <div className='grid sm:grid-cols-4 grid-cols-1 gap-4'>

                                        <div className=" mt-1 ">
                                            <div className="bg-slate-500 text-white p-1"> { t('Label Website')}   1</div>
                                            <input
                                                defaultValue={data.lwebsite1}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Name"
                                                onChange={(e) => setData({ ...data, lwebsite1: e.target.value })}
                                            />
                                            <div className="bg-slate-500 text-white p-1"> { t('E-mail')}    1</div>
                                            <input
                                                defaultValue={data.website1}
                                                type="email"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="E-mail"
                                                onChange={(e) => setData({ ...data, website1: e.target.value })}
                                            />
                                        </div>

                                        <div className=" mt-1 ">
                                            <div className="bg-slate-500 text-white p-1"> { t('Label Website')}  2</div>
                                            <input
                                                defaultValue={data.lwebsite2}
                                                type="text"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="Name"
                                                onChange={(e) => setData({ ...data, lwebsite2: e.target.value })}
                                            />
                                            <div className="bg-slate-500 text-white p-1">  { t('Website')}   2</div>
                                            <input
                                                defaultValue={data.website2}
                                                type="email"
                                                className={`${inputstyle}  shadow  w-[100%] `}
                                                placeholder="E-mail"
                                                onChange={(e) => setData({ ...data, website2: e.target.value })}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>



                  

                    </div>
                }


            </div>

        </div>
    )
}