export default function changeUrl(text:String){
    var regex = '/';
    text=text.replaceAll(regex, '-')
     regex = ' ';
     text=text.replaceAll(regex, '-')
     regex = '?';
     text=text.replaceAll(regex, '-')
     regex = '__';
     text=text.replaceAll(regex, '-')
     regex = '!';
     text=text.replaceAll(regex, '-')
     regex = '=';
     text=text.replaceAll(regex, '-')
     regex = '\\';
     text=text.replaceAll(regex, '-')
     regex = '&';
     text=text.replaceAll(regex, '-')
     regex = '#';
     text=text.replaceAll(regex, '-')

    return text;
}