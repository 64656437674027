export default function AboutUs() {
    return (
      
            <div className="text-left  bg-white  p-3 m-4 shadow  rounded-[12px] mb-5 ">
                <div className="grid grid-cols-5 gap-4">
                    <div className="col-span-5 sm:col-span-2">
                        <img src="/intro.jpg"></img>
                        <div className="flex gap-6 mt-4">
                            <img src="/about/express.svg" width={30}></img>
                            <img src="/about/mongo.svg" width={30}></img>
                            <img src="/about/node.svg" width={30}></img>
                            <img src="/about/react.svg" width={30}></img>
                            <img src="/about/tailwinds.svg" width={30}></img>

                        </div>
                    </div>

                    <div className="col-span-5 sm:col-span-3">
                        <h1 className="text-[24px] font-bold">About Software</h1>
                        <small className=" ">TISS ENGINE &copy;- Version 2.1.0</small>

                        <hr ></hr>
                    <div>
                    "TISS ENGINE is a user-friendly CMS designed for creating and managing engaging content.
                    With powerful features such as media management, interactive elements, and SEO tools,
                    TISS ENGINE helps you optimize your content for maximum impact. Our flexible and customizable
                    platform allows you to extend the functionality of your CMS and meet your unique needs.
                    Whether you're a small business, large enterprise, or nonprofit organization, TISS ENGINE
                    can help you achieve your content goals. Contact us today to learn more.
                </div>
                <div>
                    TISS ENGINE© developed by Esmail Pouryousef.
                </div>

                <div>
                    <div className="font-bold"> TISS ENGINE 2.0 features:</div>
                    <ul>
                        <li>- Changed admin panel from NextJS to ReactJs</li>
                        <li>- Implemented new API system and improved security</li>
                        <li>- Added TISS Advanced Editor 2  &copy;  and Update Image Uploader system</li>
                        <li>- Updated MongoDB driver to the latest version</li>
                        <li>- Change  managing images and articles</li>
                        <li>- Added new product manager and product entry functionality</li>
                        <li>- Added New Standart Seo Tools </li>
                        <li>- Change  Express.js Version</li>
                        <li>- Implemented Tailwinds design system</li>

                    </ul>
                </div>
                <hr />
                <div>
                    TISS ENGINE 2 © is a proprietary content management system . The software, design, and all content on the TISS ENGINE website are protected under international copyright laws. Unauthorized use, distribution, or copying of the software, design, or content is strictly prohibited and may result in legal action.
                </div>
                <div>
                    Any trademarks, service marks, and logos used in connection with TISS ENGINE are the proprietary marks of  its licensors. Use of any such marks without the express written consent of  the trademark owner is strictly prohibited.
                </div>
                <div>
                    By using TISS ENGINE, you agree to comply with all copyright laws and other applicable laws and regulations. If you have any questions about the use of TISS ENGINE or its copyright.
                </div>
                    </div>
                </div>

                <div className="text-center mt-4 bg-slate-500 text-white p-2"> © 2024 . All rights reserved.</div>

            </div>

     
    )
}