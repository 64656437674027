
import { DashboardMenu } from "../../data/menu"
import BreadCrump from "../tools/breadcrump"
import { useTranslation } from 'react-i18next';


export default function IndexGallery() {
    const { t } = useTranslation();

    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },
        {
            name: 'Setting',
            url: null
        },
    ]
    return (
        <>
            <div className="text-left  bg-white  p-3 m-4 directions   h-[100vh] mb-5 ">
                <BreadCrump data={breadcrump}></BreadCrump>
                <div className="mb-3">
                    <div className=" text-[30px] font-bold text-blue-900">{t('Blog')}</div>
                    <hr />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                    {
                        DashboardMenu.map((i) => {

                            return (
                                i.name == "Gallery" &&
                                i.sub.map((p) => {
                                    return (
                                        <a href={p.url} className="hover:text-amber-700">
                                            <div style={{ background: 'url(/assets/wallbutton.png)', backgroundSize: 'cover', border: 'solid 1px #eee' }} className="bg-gray-600 shadow-stone-800  rounded-[20px] border-b-4 text-[13px] font-bold  w-[100%] h-[100px] text-center pt-4 ">
                                                <div className="pl-3 pr-3">
                                                    <img src={i.icon} width={30}></img>
                                                </div>
                                                <div className="pt-4 text-left pl-3 pr-3" >
                                                    {t(p.name)}
                                                </div>
                                            </div>
                                        </a>
                                    )
                                })
                            )

                        })
                    }
                </div>
            </div>

        </>
    )
}