import { useCookies, Cookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import { config } from "../../data/config";
import axios from "axios";
import Swal from 'sweetalert2'

export default function AuthPage() {
  const [cookies, setCookie] = useCookies(['token', 'rule']);
  const [email, setEmail] = useState(null);
  const [password, setPasssword] = useState(null);

  const submit = async () => {
    try {

     var mydata={
      email: email,
      password: password
    };
      var path = `${config.url}/v1/auth/login`;
        console.log(email);
      let res = await axios({
        method: 'post',
        url: path,
        data:mydata,
        headers: {
            'Access-Control-Allow-Origin':'*',
 
        }
      });
      setCookie('token', res.data.token);
      setCookie('rule', res.data.rule);
      window.location.href = '/dashboard';


    } catch (error:any) {
      console.log('error')
      Swal.fire(error.response.data.message)
    }


  }
  return (
    <div className="bg-[#0299dd] h-[100vh]" dir='ltr'>
      <div className="bg-white w-[100px] absolute left-[12%] top-[2vh] shadow-md  z-[0] h-[100px] rounded-[90%]"></div>
      <div className="bg-white w-[100px] absolute right-[12%] top-[42vh] shadow-md  z-[0] h-[100px] rounded-[90%]"></div>

      <div className="pt-10 w-[70%] m-auto z-[1] relative">
        <div className="bg-white  rounded-[10px]  shadow-md    min-h-[80vh]  grid grid-cols-1 sm:grid-cols-3">
          <div style={{ backgroundImage: 'url(/intro.jpg)' }} className="bg-cover bg-bottom  rounded-[10px] min-[200vh] sm:min-[100px] col-span-1">
          </div>
          <div className="col-span-2 pt-[10vh]">
            <h1 className="text-center font-bold text-[20px] text-orange-500">Welcome to TISS Engine <small> &copy; </small> </h1>
            <h5 className="text-center font-bold text-[10px] text-gray-800" >REACT Edition <small>2.1.0</small></h5>
            <div className="m-1 sm:m-10">
              <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Username
                  </label>
                  <input onChange={(e:any) => { setEmail(e.target.value) }} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" />
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2" >
                    Password
                  </label>
                  <input onChange={(e:any) => { setPasssword(e.target.value) }} className="shadow appearance-none border  w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type={'password'} placeholder="******************" />
                </div>
                <div className="flex items-center justify-between">
                  <button onClick={(e) => { submit(); }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Sign In
                  </button>

                </div>
              </form>
            </div>

            <div className="text-gray-600  text-[10px]  text-center">Programmed by :<strong>Esmaiel Pouryosef</strong></div>
            <div className="text-gray-600  text-[10px]  text-center">Version :<strong>2.1.0</strong></div>

          </div>
        </div>
      </div>
    </div>
  )
}