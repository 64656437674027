
import * as React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import { getCookies } from "cookies-next";

import {
    ClassicEditor,
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    Code,
    Essentials,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    Indent,
    IndentBlock,
    Italic,
    Link,
    Paragraph,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Underline,
    Undo
} from 'ckeditor5';


import 'ckeditor5/ckeditor5.css';
import { config } from '../../data/config';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';


export default function TissText({ data, reload }: any) {
    const { t } = useTranslation();

    let cookie = getCookies();

    const [newitem, setItemEdit] = React.useState<any>(data.data == undefined ? false : true);
    const [show, setShow] = React.useState<any>(true);
    const [text, setText] = React.useState<any>(data.data != undefined ? data.data.html : '');

    const startedit = () => {
        setItemEdit(true);
        setShow(false);
    }
    const backtopreviw = () => {
        setItemEdit(true);
        setShow(true);
    }
    const inputHandler = (event: any, editor: any) => {
        setShow(editor.getData())
        setText(editor.getData())
    };
    const save = async () => {
        try {
            var path = `${config.url}/v1/dashboard/component/texteditor/${data._id}`;

            let res = await axios({
                method: 'put',
                data: {
                    html: text
                },
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            Swal.fire('update');
        } catch (error) {

        }
    }
    return (
        <div className='w-[100%] p-4' dir='ltr'>
            {
                newitem == false ?
                    <div onClick={(e) => { startedit() }}>
                        <img src='/icons/addtext.svg' className='h-[40px] m-auto'></img>

                    </div>
                    : <div>

                        {
                            show == true ?
                                <div dir='ltr'>
                                    <div className='w-[100%] text-left ' dangerouslySetInnerHTML={{ __html: text }} />
                                    <div className='mt-3'>
                                        <button onClick={(e) => {
                                            startedit();
                                        }}
                                            className=" hover:bg-blue-700  hover:text-white text-black font-bold  px-4 border border-blue-700 rounded">
                                            {t('Edit')}
                                        </button>
                                    </div>
                                </div>

                                :
                                <div>  <CKEditor
                                    editor={ClassicEditor}
                                    onChange={inputHandler}

                                    config={{
                                        toolbar: {
                                            items: [
                                                '|',
                                                'bold',
                                                'italic',
                                                'underline',
                                                'strikethrough',
                                                'subscript',
                                                'superscript',
                                                'code',
                                                'removeFormat',
                                                '|',
                                                'heading',
                                                'fontSize',
                                                'fontFamily',
                                                'fontColor',
                                                'fontBackgroundColor',
                                                '|',
                                                'specialCharacters',
                                                'horizontalLine',
                                                'link',
                                                'insertTable',
                                                'highlight',
                                                'blockQuote',
                                                '|',
                                                'alignment',
                                                '|',
                                                'outdent',
                                                'indent',
                                                'undo',
                                                'redo',
                                                '|',
                                                'sourceEditing',
                                                'showBlocks',
                                                'findAndReplace',
                                                '|',



                                            ],
                                            shouldNotGroupWhenFull: false
                                        },
                                        plugins: [
                                            AccessibilityHelp,
                                            Alignment,
                                            Autoformat,
                                            AutoLink,
                                            Autosave,
                                            BlockQuote,
                                            Bold,
                                            Code,
                                            Essentials,
                                            FindAndReplace,
                                            FontBackgroundColor,
                                            FontColor,
                                            FontFamily,
                                            FontSize,
                                            GeneralHtmlSupport,
                                            Heading,
                                            Highlight,
                                            HorizontalLine,
                                            Indent,
                                            IndentBlock,
                                            Italic,
                                            Link,
                                            Paragraph,
                                            RemoveFormat,
                                            SelectAll,
                                            ShowBlocks,
                                            SourceEditing,
                                            SpecialCharacters,
                                            SpecialCharactersArrows,
                                            SpecialCharactersCurrency,
                                            SpecialCharactersEssentials,
                                            SpecialCharactersLatin,
                                            SpecialCharactersMathematical,
                                            SpecialCharactersText,
                                            Strikethrough,
                                            Style,
                                            Subscript,
                                            Superscript,
                                            Table,
                                            TableCaption,
                                            TableCellProperties,
                                            TableColumnResize,
                                            TableProperties,
                                            TableToolbar,
                                            TextTransformation,
                                            Underline,
                                            Undo
                                        ],
                                        initialData: text,
                                    }}
                                />

                                    <div className='mt-3 flex gap-4'>
                                        <button onClick={(e) => {
                                            save();
                                        }} className=" hover:bg-blue-700  hover:text-white text-black font-bold  px-4 border border-blue-700 rounded">
                                            {t('Apply')}
                                        </button>
                                        <button onClick={(e) => {
                                            backtopreviw();
                                        }} className=" hover:bg-blue-700  hover:text-white text-black font-bold  px-4 border border-blue-700 rounded">
                                            {t('Preview')}
                                        </button>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                        }
                    </div>
            }


        </div>
    )
}