
import axios from 'axios';
import React, { useEffect } from 'react';
import ImageUploading from 'react-images-uploading';

import { config } from '../../data/config';
import { getCookies } from "cookies-next";
import { useTranslation } from 'react-i18next';


export default function ImageUploader({ count = 40, id, component }:any) {
    const { t } = useTranslation();

    const [images, setImages] = React.useState([]);
    const [listimage, setlistimage] = React.useState([]);

    const maxNumber = 69;
    let cookie = getCookies();

    const onChange = async (imageList:any, addUpdateIndex:any) => {
        console.log(imageList[0]);
        let formData = new FormData();
        formData.append("file", imageList[0].file);
        formData.append("component", component);
        formData.append("parent", id);

        await axios({
            method: "post",
            url: config.url + "/v1/dashboard/filemanager/single",
            data: formData,
            headers: {
                Authorization: 'Bearer ' + cookie['token'],
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function (response) {
                loaditem();
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });

        setImages(imageList);

        return true;

    };
    const loaditem = async () => {
        await axios({
            method: "get",
            url: config.url + "/v1/dashboard/filemanager?id=" + id + "&component=" + component,
            headers: {
                Authorization: 'Bearer ' + cookie['token'],
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function (response) {
                // loaditem()
                setlistimage(response.data.data)
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    }
    const deletedItem = async (myid:any) => {
        await axios({
            method: "delete",
            url: config.url + "/v1/dashboard/filemanager/" + myid,
            headers: {
                Authorization: 'Bearer ' + cookie['token'],
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function (response) {
                loaditem()

            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    };
    useEffect(() => {
        loaditem();
    }, []);
    return (
        <div className="App">
            <div>

                {listimage.map((item:any) => {
                    return (
                        <div>
                            <img src={config.url + '/' + item.url} ></img>
                            <button
                                onClick={(e) => {
                                    deletedItem(item.id);
                                }}
                                className='w-[100%] h-[50px] bg-red-700 text-white p-2 rounded mb-4'
                            >
                                {t('Delete')}
                            </button>
                        </div>
                    )
                })}
            </div>
            {listimage.length == 0 &&
                <ImageUploading
                    multiple
                    
                    value={images}
                    onChange={onChange}
                    maxNumber={count}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                    }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <button
                                style={isDragging ? { color: 'red' } : undefined}
                                onClick={onImageUpload}
                                {...dragProps}
                                className='w-[100%] h-[50px] bg-gray-500 text-white p-2 rounded '
                            >
                                 {t('Click or Drop here')}
                                
                            </button>

                        </div>
                    )}
                </ImageUploading>
            }
        </div>
    );
}