import BreadCrump from '../tools/breadcrump';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { config } from "../../data/config";
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useState } from "react";
import ImageUploader from '../tools/uploaderImage';
import axios from 'axios';
import { getCookies } from "cookies-next";
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

export default function SocialPage() {
    const { t } = useTranslation();

    const [data, setData] = React.useState<any>(null);
    const [key, setKey] = useState<any>(1);
    const [error, setError] = useState<any>(null);
    let cookie = getCookies();

    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none shadow";
    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },

        {
            name: 'Setting',
            url: '/dashboard/setting'
        },
        {
            name: 'Social Network',
            url: null
        },
    ]
    const load = async () => {
        try {
            var path = `${config.url}/v1/dashboard/socialnetwork`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setData(res.data.message)
        } catch (error) {

        }
    }
    const save = async () => {
        try {
            var path = `${config.url}/v1/dashboard/socialnetwork/${data.id}`;

            let res = await axios({
                method: 'put',
                data: data,
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            Swal.fire('Saved');
        } catch (error) {

        }
    }
    useEffect(() => {
        load();
    }, []);
    return (
        <div>
            <div className="text-left  bg-white  p-3 m-4   h-[100vh] mb-5 ">


                <BreadCrump data={breadcrump}></BreadCrump>
                <div>
                    <form>
                        <div className="p-3  rounded-[10px] directions ">
                            <h4 className="text-black text-[20px] font-bold "> {t('Social Network')} </h4>
                            <hr />

                        </div>
                    </form>
                </div>


                {
                    data != null && <div >
                        <div className=' w-[100%]  '>
                            <button
                                onClick={(e) => { save() }}
                                type="button" className="  gap-x-2 text-sm font-medium rounded-full border border-transparent bg-gray-700 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 w-[100px] text-center p-4 m-auto disabled:pointer-events-none">
                                {t('Save')}
                            </button>
                        </div>
                        <div>
                            <form key={key} className='grid grid-cols-1 sm:grid-cols-2 p-4' >

                                <div className="max-w-sm space-y-3">
                                    <label className=""> Facebook </label>
                                    <input
                                        defaultValue={data.facebook}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, facebook: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> Whatsapp </label>
                                    <input
                                        defaultValue={data.whatsapp}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, whatsapp: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> Youtube </label>
                                    <input
                                        defaultValue={data.youtube}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, youtube: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> instagram </label>
                                    <input
                                        defaultValue={data.instagram}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, instagram: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> wechat </label>
                                    <input
                                        defaultValue={data.instagram}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, wechat: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> wechat </label>
                                    <input
                                        defaultValue={data.tiktok}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, tiktok: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> wechat </label>
                                    <input
                                        defaultValue={data.telegram}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, telegram: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> Telegram Chanal </label>
                                    <input
                                        defaultValue={data.telegramchanal}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, telegramchanal: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> snapchat </label>
                                    <input
                                        defaultValue={data.telegramchanal}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, snapchat: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> X(twitter) </label>
                                    <input
                                        defaultValue={data.twitter}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, twitter: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> pinterest </label>
                                    <input
                                        defaultValue={data.pinterest}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, pinterest: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> reddit </label>
                                    <input
                                        defaultValue={data.reddit}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, reddit: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> linkedin </label>
                                    <input
                                        defaultValue={data.linkedin}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, linkedin: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> discort </label>
                                    <input
                                        defaultValue={data.discort}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, discort: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> sorosh </label>
                                    <input
                                        defaultValue={data.sorosh}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, sorosh: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> rubika </label>
                                    <input
                                        defaultValue={data.rubika}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, rubika: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> igap </label>
                                    <input
                                        defaultValue={data.rubika}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, igap: e.target.value })}
                                    />
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className=""> eitaa </label>
                                    <input
                                        defaultValue={data.eitaa}
                                        type="text"
                                        className={`${inputstyle}  shadow    ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, eitaa: e.target.value })}
                                    />
                                </div>


                            </form>

                        </div>



                    </div>
                }


            </div>

        </div>
    )
}