import { DashboardMenu } from "../data/menu"
import { useTranslation } from 'react-i18next';

export default function Dashboard() {
    const { t } = useTranslation();

    return (
        <>
            <div className="h-[100vh] directions.7  ">
                <div className="mb-3">
                    <div className=" text-[30px] font-bold text-blue-900">{t('Dashboard')} </div>
                    <hr />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">

                    {
                        DashboardMenu.map((item: any) => (
                            item.show == true &&
                            <div style={{ backgroundColor: item.color }} className={`  h-[150px] p-3 rounded-[10px] shadow`}>
                                <div className="grid grid-cols-6">
                                    <div className="col-span-1 pt-4">
                                        <img src={item.icon} width={30}></img>
                                    </div>
                                    <div className="col-span-5">
                                        <div className='text-end text-[20px] font-bold ' >
                                            <a href={item.url} >
                                                {t(item.name)}
                                            </a>
                                        </div>
                                        <ul className=' text-[14px]  '>
                                            {item.sub.map((items: any) => {
                                                return (<li className="text-end"><a href={items.url} > {t(items.name)}</a></li>)
                                            })}

                                        </ul>

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}