import { useParams } from 'react-router-dom';
import { config } from "../../data/config";
import { useEffect } from 'react';
import { getCookies } from "cookies-next";
import { useCookies } from 'react-cookie';
import axios from "axios";
import { useState } from "react";

import * as React from 'react';
import TissEditor from '../Editor/TissEditor';
import ImageUploader from '../tools/uploaderImage';
import BreadCrump from '../tools/breadcrump';
import Swal from 'sweetalert2';

export default function EdtiUser() {
    let cookie = getCookies();

    let { Id } = useParams();
    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [key, setKey] = useState<any>(1);
    const [error, setError] = useState<any>(null);
    const [list, setListArticle] = useState<any>(null);

    const [data, setData] = React.useState<any>(null);
    const save = async () => {
        try {
            var path = `${config.url}/v1/dashboard/users/${Id}`;

            let res = await axios({
                method: 'put',
                data: data,
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            Swal.fire('Update Data')
        } catch (error) {

        }
    }
    const load = async () => {
        try {
            var path = `${config.url}/v1/dashboard/users/${Id}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setData(res.data.message)
        } catch (error) {

        }
    }
    const getalldata = async () => {
        setError(null);

        try {
            let res = await axios({
                method: 'get',
                url: config.url + '/v1/dashboard/users',
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });


            setListArticle(res.data.message);
        } catch (error) {


        }
    }
    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none";
    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },
        {
            name: 'Users',
            url: '/dashboard/User/'
        },
        {
            name: 'List User',
            url: '/dashboard/User/list'
        },
        {
            name: 'Edit User',
            url: null
        },
    ]
    useEffect(() => {
        getalldata();
        load();
    }, []);
    return (
        <div>
            <BreadCrump data={breadcrump}></BreadCrump>

            <button onClick={(e) => { load() }}>List</button>

            {
                data != null && <div className='grid grid-cols-1 sm:grid-cols-3 gap-1'>
                    <div >

                        <div className="p-3 bg-gray-500 rounded-[10px] p-5">
                            <h4 className="text-white text-[20px] font-bold ">Edit User</h4>
                            <hr />
                            <form key={key}>
                                <div className="max-w-sm space-y-3">
                                    <label className="text-white"> Name </label>
                                    <input
                                        defaultValue={data.name}
                                        type="text"
                                        className={`${inputstyle}     ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="text-white"> Last Name </label>
                                    <input
                                        type="text"
                                        defaultValue={data.lastname}
                                        className={`${inputstyle}       ${error != null && error['url'] != undefined && `bg-red-100`}  `}
                                        placeholder="Slug"
                                        onChange={(e) => setData({ ...data, lastname: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['url']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="text-white"> E-mail </label>
                                    <input

                                        type="text"
                                        defaultValue={data.email}
                                        className={`${inputstyle}        ${error != null && error['url'] != undefined && `bg-red-100`}  `}
                                        placeholder="Slug"
                                        onChange={(e) => setData({ ...data, lastname: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['url']['message']}</small>}
                                </div>
                                <div className="col-span-2 pt-6" >
                                <label className="text-white"> User Type </label>

                                    <select
                                        onChange={(e) => { setData({ ...data, usertype: e.target.value }) }}
                                        defaultValue={data.usertype}
                                        className="block appearance-none w-full  border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white " id="grid-state" >
                                        <option value={1}>Admin</option>
                                        <option value={2}>User</option>

                                    </select>
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <button type="button"
                                        onClick={(e) => { save() }}
                                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:focus:bg-white/20 dark:focus:text-white"
                                    >
                                        Update Post
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-span-1 sm:col-span-1'>

                    <div className="p-3 bg-gray-500 rounded-[10px] p-5">
                            <h4 className="text-white text-[20px] font-bold ">Change Password</h4>
                            <hr />
                            <form key={key}>
                                <div className="max-w-sm space-y-3">
                                    <label className="text-white"> New Password </label>
                                    <input
                                        type="text"
                                        className={`${inputstyle}     ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="text-white"> Confirm Password </label>
                                    <input
                                        type="password"
                                        className={`${inputstyle}       ${error != null && error['url'] != undefined && `bg-red-100`}  `}
                                        onChange={(e) => setData({ ...data, lastname: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['url']['message']}</small>}
                                </div>
                               
                               
                                <div className="max-w-sm space-y-3 mt-4">
                                    <button type="button"
                                        onClick={(e) => { save() }}
                                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:focus:bg-white/20 dark:focus:text-white"
                                    >
                                        Change Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-span-1 sm:col-span-1'>

                        <div className='mt-3'>
                            <div className=" text-left"> Thumpnail </div>

                            <ImageUploader id={data.id} component={'Gallery'}></ImageUploader>

                        </div>
                    </div>
                </div>
            }

        </div>
    )
}