import { useParams } from 'react-router-dom';
import { config } from "../../data/config";
import { useEffect } from 'react';
import { getCookies } from "cookies-next";
import { useCookies } from 'react-cookie';
import axios from "axios";
import { useState } from "react";
import BreadCrump from "../tools/breadcrump"

import * as React from 'react';
import TissEditor from '../Editor/TissEditor';
import ImageUploader from '../tools/uploaderImage';
import ImageUploaders from '../tools/uploaderimages';
import Swal from 'sweetalert2';

export default function EditDetail() {
    let cookie = getCookies();

    let { Id } = useParams();
    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [key, setKey] = useState<any>(1);
    const [error, setError] = useState<any>(null);

    const [data, setData] = React.useState<any>(null);
    const save = async () => {
        try {
            var path = `${config.url}/v1/dashboard/filemanager/${Id}`;
            
            let res = await axios({
                method: 'put',
                url: path,
                data: data,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            Swal.fire('Saved');
        } catch (error) {

        }
    }
    const load = async () => {
        try {
            var path = `${config.url}/v1/dashboard/filemanager/${Id}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setData(res.data.data)
        } catch (error) {

        }
    }
    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none";
    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },
        {
            name: 'Gallery',
            url: '/dashboard/Gallery'
        },

        {
            name: 'Edit Detail',
            url: null
        }
    ]
    useEffect(() => {
        load();
    }, []);
    return (
        <div>

            <BreadCrump data={breadcrump}></BreadCrump>


            {
                data != null && <div className='grid grid-cols-1 sm:grid-cols-5'>
                    <div >

                        <div className="p-3 bg-gray-500 rounded-[10px] p-5">
                            <h4 className="text-white text-[20px] font-bold ">Edit Detail</h4>
                            <hr />
                            <form key={key}>
                                <img src={`${config.url}/${data.url}`} ></img>
                                <div className="max-w-sm space-y-3">
                                    <label className="text-white"> Title </label>
                                    <input
                                        defaultValue={data.title}
                                        type="text"
                                        className={`${inputstyle}     ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, title: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3">
                                    <label className="text-white"> Link </label>
                                    <input
                                        defaultValue={data.link}
                                    
                                        className={`${inputstyle}     ${error != null && error['name'] != undefined && `bg-red-100`} `}
                                        placeholder="Name"
                                        onChange={(e) => setData({ ...data, link: e.target.value })}
                                    />
                                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="text-white"> Keywords </label>
                                    <textarea
                          
                                        defaultValue={data.keywords}


                                        className={inputstyle}
                                        onChange={(e) => setData({ ...data, keywords: e.target.value })}
                                        placeholder="..." />
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <label className="text-white"> Description </label>
                                    <textarea
                                    
                                        defaultValue={data.description}

                                        className={inputstyle}
                                        onChange={(e) => setData({ ...data, description: e.target.value })}
                                        placeholder="..." />
                                </div>
                                <div className="max-w-sm space-y-3 mt-4">
                                    <button type="button"
                                        onClick={(e) => { save() }}
                                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:focus:bg-white/20 dark:focus:text-white"
                                    >
                                        Update Detail
                                    </button>

                                </div>
                            </form>
                            <div className='mt-3'>


                            </div>


                        </div>
                    </div>
                    <div className='col-span-1 sm:col-span-4 p-5'>
                        <TissEditor component='detailImage' parent={data.id} />
                    </div>
                </div>
            }

        </div>
    )
}