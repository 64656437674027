import { config } from "../data/config"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items:1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
export default function CarsoulSlider({ data }: any) {

    return (
        <div>
            <Carousel responsive={responsive}>
                    {
                        data.map((item: any, index: any) => {
                            return (
                                <div className="hs-carousel-slide  w-full h-[100px] sm:h-[500px]">
                                    <img className="w-[100%]" src={config.url + item.url} ></img>
                                </div>
                            )
                        })
                    }
            
            </Carousel>


        </div>
    )
}