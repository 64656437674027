import { DashboardMenu } from "../data/menu";
import { useTranslation } from 'react-i18next';

export default function NavCanvas() {
    const { t } = useTranslation();

    return (
        <>
            <button type="button" className="py-3 flex float-start px-4  items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-white text-white hover:bg-slate-400 focus:outline-none focus:bg-slate-500 disabled:opacity-50 disabled:pointer-events-none" aria-haspopup="dialog" aria-expanded="false" aria-controls="hs-offcanvas-example" data-hs-overlay="#hs-offcanvas-example">
                <img src="/icons/nav.svg" width={20} height={20} />
                <div className="text-black">{t('Menu')} </div>
            </button>

            <div id="hs-offcanvas-example" className="hs-overlay hs-overlay-open:translate-x-0 hidden -translate-x-full fixed top-0 start-0 transition-all duration-300 transform h-full max-w-xs w-full z-[80] bg-white border-e " role="dialog" tabIndex={-1} aria-labelledby="hs-offcanvas-example-label">
                <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                    <h3 id="hs-offcanvas-example-label" className="font-thin text-gray-800 ">
                        Tiss Engine 2.0
                    </h3>
                    <button type="button" className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 " aria-label="Close" data-hs-overlay="#hs-offcanvas-example">
                        <span className="sr-only">Close</span>
                        <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M18 6 6 18"></path>
                            <path d="m6 6 12 12"></path>
                        </svg>
                    </button>
                </div>
                <div className="p-4">
                    <ul className="text-left ">
                        {
                            DashboardMenu.map((item:any) => (
                                <li className="mt-4" >
                                    <a className="flex gap-2 text-black hover:text-blue" href={item.url}>
                                        <img src={item.icon} width={20}></img>
                                        {t(item.name)}
                                    </a>
                                </li>
                            ))
                        }


                    </ul>
                </div>
            </div>
        </>
    )
}