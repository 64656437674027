
import { DashboardMenu } from "../../data/menu"
import BreadCrump from "../tools/breadcrump"
import { getCookies } from "cookies-next";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import changeUrl from "../../hoc/changeur";
import { config } from "../../data/config";
import ImageUploader from "../tools/uploaderImage"

import { useTranslation } from 'react-i18next';

export default function ListGallery() {
    const { t } = useTranslation();

    const breadcrump = [
        {
            name: 'Dashboard',
            url: '/dashboard'
        },
        {
            name: 'Gallery',
            url: '/dashboard/Gallery'
        },
        {
            name: 'Gallery List',
            url: null
        },
    ]
    const reloadgame = () => {
        let x = key + 1;
        setKey(x);
    }
    const [key, setKey] = useState<any>(1);

    return (
        <>
            <div className="  bg-white  p-3 m-4   h-[100vh] mb-5 ">
                <BreadCrump data={breadcrump}></BreadCrump>
                <div className="mb-3">

                    <div className=" text-[30px] font-bold text-blue-900">{t('Gallery List')}
                    </div>
                    <hr />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <NewBlogCategory reloaditem={reloadgame}></NewBlogCategory>

                    </div>
                    <div className="col-span-3">
                        <ListofBlog key={key}></ListofBlog>
                    </div>
                </div>
            </div>

        </>
    )
}
function ListofBlog() {
    const { t } = useTranslation();

    let cookie = getCookies();
    const [list, setList] = useState<any>(null);

    const [error, setError] = useState<any>(null);
    const [key, setKey] = useState<any>(1);

    const getalldata = async () => {
        setError(null);

        try {
            let res = await axios({
                method: 'get',
                url: config.url + '/v1/dashboard/gallery',
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });

            //let mydata = res.data;
            //  console.log(config.url + '/v1/dashboard/bloggroup')
            //  console.log(res);
            setList(res.data.message);
        } catch (error: any) {

            // error.response.data.code != null && Swal.fire(` "${data.url}" slug is available.`);
            setError(error.response.data.message.errors);
        }
    }
    const deleted = async (id: any) => {
        Swal.fire({
            title: t('Are you sure?'),
            text: t('Delete this Group!'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let res = await axios({
                        method: 'delete',
                        url: config.url + '/v1/dashboard/gallery/' + id,
                        headers: {
                            Authorization: 'Bearer ' + cookie['token'],
                        }
                    })

                    getalldata();
                } catch (error) {
                    console.log(error);
                }
            }
        });

    }
    useEffect(() => {
        getalldata();
    }, []);
    return (
        <div className="directions">
            <div className="grid grid-cols-2 pb-4">
                <div>
                    <p className="font-bold ">{t('Gallery List')} </p>

                </div>
                <div className="  gap-1 w-full">
                    <button type="button" onClick={(e) => { getalldata() }} className="float-end py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none ">
                        {t('Reload')}
                    </button>
                </div>
            </div>
            <hr />

            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                    <div className="p-1.5 min-w-full inline-block align-middle">
                        <div className="overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200 ">
                                <thead>
                                    <tr>
                                        <th scope="col" className="px-6 py-3  text-xs font-medium text-gray-500 uppercase ">{t('Name')}    </th>
                                        <th scope="col" className="px-6 py-3  text-xs font-medium text-gray-500 uppercase ">{t('SLUG')} </th>

                                        <th scope="col" className="px-6 py-3  text-xs font-medium text-gray-500 uppercase  ">{t('Action')}  </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list != null && list.map((item: any) => {
                                        return (
                                            <tr className="odd:bg-white even:bg-gray-100 hover:bg-gray-100 ">
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">{item.name}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">{item.url}</td>

                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium flex gap-2">
                                                    <a
                                                        href={`/dashboard/Gallery/gallery/${item.id}`}
                                                        type="button"
                                                        className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none "
                                                    >{t('Edit')} </a>

                                                    <button
                                                        onClick={(e) => { deleted(item.id) }}
                                                        type="button"
                                                        className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none "
                                                    >{t('Delete')} </button>
                                                </td>

                                            </tr>
                                        )
                                    })}



                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function NewBlogCategory({ reloaditem }: any) {
    const { t } = useTranslation();

    let cookie = getCookies();
    const [data, setData] = useState<any>({
        name: '',
        url: '',

        keywords: '',
        description: '',
        parent: null

    });
    const [error, setError] = useState<any>(null);
    const [key, setKey] = useState<any>(1);
    const setName = (name: any) => {


        var x = changeUrl(name);
        setData({ ...data, url: x });
        setData({ ...data, name: name });
    }
    const save = async () => {
        setError(null);

        try {
            let res = await axios({
                method: 'post',
                url: config.url + '/v1/dashboard/gallery',
                data: data,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });

            let mydata = res.data;
            reloaditem();
            let x = key + 1;
            setKey(x);
            return mydata;
        } catch (error: any) {

            error.response.data.code != null && Swal.fire(` "${data.url}" slug is available.`);
            setError(error.response.data.message.errors);
        }
    }

    const inputstyle = "py-3 px-5 block w-full border-gray-499 rounded text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none";
    return (
        <div className="p-3 bg-gray-500 rounded-[10px] p-5">
            <h4 className="text-white text-[20px] font-bold ">{t('New Gallery')} </h4>
            <hr />

            <form key={key}>
                <div className="max-w-sm space-y-3">
                    <label className="text-white">{t('Gallery')}  </label>
                    <input
                        type="text"
                        className={`${inputstyle}     ${error != null && error['name'] != undefined && `bg-red-100`} `}
                        placeholder="Name"
                        onChange={(e) => { setName(e.target.value);; }}
                    />
                    {error != null && error['url'] != undefined && <small>{error['name']['message']}</small>}
                </div>
                <div className="max-w-sm space-y-3 mt-4">
                    <label className="text-white">{t('Slug')}   </label>
                    <input
                        value={data.url}
                        type="text"
                        className={`${inputstyle}       ${error != null && error['url'] != undefined && `bg-red-100`}  `}
                        placeholder="Slug"
                        onChange={(e) => setData({ ...data, url: e.target.value })}
                    />
                    {error != null && error['url'] != undefined && <small>{error['url']['message']}</small>}
                </div>
                <div className="max-w-sm space-y-3 mt-4">
                    <label className="text-white">{t('Keywords')}   </label>
                    <textarea
                        className={inputstyle}
                        onChange={(e) => setData({ ...data, keywords: e.target.value })}
                        placeholder="..." />
                </div>
                <div className="max-w-sm space-y-3 mt-4">
                    <label className="text-white">{t('Description')}     </label>
                    <textarea
                        className={inputstyle}
                        onChange={(e) => setData({ ...data, description: e.target.value })}
                        placeholder="..." />
                </div>
                <div className="max-w-sm space-y-3 mt-4">
                    <button type="button"
                        onClick={(e) => { save() }}
                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:focus:bg-white/20 dark:focus:text-white"
                    >
                        {t('Add Gallery')}
                    </button>

                </div>
            </form>
        </div>
    )
}