
import * as React from 'react';
import axios from 'axios';
import { getCookies } from "cookies-next";
import ImageUploading from 'react-images-uploading';


import { config } from '../../data/config';
import Swal from 'sweetalert2';
import ImageUploader from '../tools/uploaderImage';
import { useTranslation } from 'react-i18next';


export default function TissImage({ data, reload }:any) {
    const { t } = useTranslation();

    let cookie = getCookies();

    const [newitem, setItemEdit] = React.useState(data.toimage == null ? false : true);
    const [show, setShow] = React.useState(true);
    const [text, setText] = React.useState(data.toimage != null ? 'd' : '');

    const startedit = () => {
        setItemEdit(true);
        setShow(false);
    }
    const backtopreviw = () => {
        setItemEdit(true);
        setShow(true);
    }
    const inputHandler = (event:any, editor:any) => {
        setShow(editor.getData())
        setText(editor.getData())
    };
    const save = async () => {

        try {
            var path = `${config.url}/v1/dashboard/component/texteditor/${data._id}`;

            let res = await axios({
                method: 'put',
                data: {
                    html: text
                },
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            Swal.fire('update');
        } catch (error) {

        }
    }
    return (
        <div className='w-[100%] p-4' dir='ltr'>
            <div>
            </div>
            {
                newitem == false ?
                    <div onClick={(e) => { startedit() }}>
                        <img src='/icons/addimage.svg' className='h-[40px] m-auto'></img>
                    </div>
                    : <div>

                        {

                            <div>
                                <ImageUploader id={data.id} component={'ImageEditor'}></ImageUploader>



                                <div className='mt-3 flex gap-4'>
                                    <button onClick={(e) => {
                                        save();
                                    }} className=" hover:bg-blue-700  hover:text-white text-black font-bold  px-4 border border-blue-700 rounded">
                                       {t('Apply')} 
                                    </button>

                                </div>
                                <div>

                                </div>
                            </div>
                        }
                    </div>
            }


        </div>
    )
}