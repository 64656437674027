
import * as React from 'react';
import axios from 'axios';
import { getCookies } from "cookies-next";



import 'ckeditor5/ckeditor5.css';
import { config } from '../../data/config';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

export default function TissHeading({ data, reload }:any) {
    const { t } = useTranslation();
    let cookie = getCookies();
    

    const [newitem, setItemEdit] = React.useState(data.data == undefined ? false : true);
    const [show, setShow] = React.useState(true);
    const [text, setText] = React.useState(data.data != undefined ? data.data.text : '');
    const [mode, setMode] = React.useState(data.data != undefined ? data.data.mode : 'h1');

    const startedit = () => {
        setItemEdit(true);
        setShow(false);
    }
    const backtopreviw = () => {
        setItemEdit(true);
        setShow(true);
    }
    const inputHandler = (event:any, editor:any) => {
        setShow(editor.getData())
        setText(editor.getData())
    };
    const save = async () => {
        try {
            var path = `${config.url}/v1/dashboard/component/texteditor/${data._id}`;

            let res = await axios({
                method: 'put',
                data: {
                    text: text,
                    mode: mode
                },
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            Swal.fire('update');
        } catch (error) {

        }
    }
    return (
        <div className='w-[100%] p-4' dir='ltr'>
            {
                newitem == false ?
                    <div onClick={(e) => { startedit() }}>
                        <img src='/icons/addheading.svg' className='h-[40px] m-auto'></img>

                    </div>
                    : <div>

                        {
                            show == true ?
                                <div dir='ltr' className='text-left'>

                                    {mode == 'h1' && <h1 className='text-[24px]'>{text}</h1>}
                                    {mode == 'h2' && <h2 className='text-[20px]'>{text}</h2>}
                                    {mode == 'h3' && <h3 className='text-[18px]'>{text}</h3>}
                                    {mode == 'h4' && <h4 className='text-[16px]'>{text}</h4>}
                                    {mode == 'h5' && <h5 className='text-[14px]'>{text}</h5>}
                                    {mode == 'h6' && <h6 className='text-[12px]'>{text}</h6>}

                                    <div className='mt-3'>
                                        <button onClick={(e) => {
                                            startedit();
                                        }} className=" hover:bg-blue-700  hover:text-white text-black font-bold  px-4 border border-blue-700 rounded">
                                           {t('Edit')} 
                                        </button>
                                    </div>
                                </div>

                                :
                                <div>
                                    <div dir='ltr' className='text-left'>
                                        <div className='text-left'>Mode</div>
                                        <select
                                            defaultValue={mode}
                                            onChange={(e) => { setMode(e.target.value) }}
                                            className="py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                            <option value='h1'>Heading 1</option>
                                            <option value='h2'>Heading 2</option>
                                            <option value='h3'>Heading 3</option>
                                            <option value='h4'>Heading 4</option>
                                            <option value='h5'>Heading 5</option>
                                            <option value='h6'>Heading 6</option>
                                        </select>
                                        <div className='text-left'>Text</div>
                                        <div className="">
                                            <input
                                                defaultValue={text}
                                                onChange={(e) => { setText(e.target.value) }}
                                                type="text" className="py-3 w-full px-4 block border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                                placeholder="Title Here" />
                                        </div>
                                    </div>


                                    <div className='mt-3 flex gap-4'>
                                        <button onClick={(e) => {
                                            save();
                                        }} className=" hover:bg-blue-700  hover:text-white text-black font-bold  px-4 border border-blue-700 rounded">
                                          {t('Apply')}    
                                        </button>
                                        <button onClick={(e) => {
                                            backtopreviw();
                                        }} className=" hover:bg-blue-700  hover:text-white text-black font-bold  px-4 border border-blue-700 rounded">
                                           {t('Preview')}      
                                        </button>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                        }
                    </div>
            }


        </div>
    )
}