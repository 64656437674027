
import { red } from '@mui/material/colors';
import { getCookies, setCookie, deleteCookie , hasCookie} from 'cookies-next';
import { useEffect } from 'react';
import { Navigate, redirect } from 'react-router-dom';

export function LogOut(){
    useEffect(() => {
        deleteCookie('token');
        deleteCookie('rule');
        redirect('/login');
        
  
    }, []);
    return(
        <Navigate to="/login" />
    )
}