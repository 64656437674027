import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your translation files
import enTranslation from './locales/en/translation.json';
import frTranslation from './locales/fa/translation.json';

i18n
  .use(LanguageDetector)  // Detects user language
  .use(initReactI18next)   // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: enTranslation },
      fa: { translation: frTranslation }
    },
    fallbackLng: 'en',    // Fallback language if language detection fails
    interpolation: { escapeValue: false }  // React already escapes values
  });

export default i18n;
